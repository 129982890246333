import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Redirect } from 'react-router';
import i18n from "i18next";

import * as ui from '../../utilities/ui';
import * as content from '../../utilities/content';

import ImgCover from '../../elements/img-cover';
import BookPrice from '../../elements/book-price';
import BlockBookActions from './block-book-actions';
import BlockPreviewPdf from './block-preview-pdf';
import BookRanking from '../../elements/book-ranking';

const BlockCover = inject("stores") (
    observer (
        class BlockCover extends React.PureComponent {

            constructor(props) {
                super(props);
                this.props = props;
                this.urlAPI = process.env.REACT_APP_URL_API;

                this.state = {
                    redirect: false,
                    has_pdf: false,
                    has_ranking: false,
                    ranking: 0
                }

                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeUser = this.props.stores.storeUser;
                this.storeOrder = this.props.stores.storeOrder;
                this.storeStock = this.props.stores.storeStock;
                this.storeVisuals = this.props.stores.storeVisuals;

                this.controller = new AbortController();
                this.signal = this.controller.signal;
            }

            async componentDidMount() {
                this.getProduct();
                const ranking = this.findRanking(this.props.sku);
                this.setState({
                    ranking: ranking,
                    has_ranking: ranking == 0 ? false : true
                })
            }

            async componentDidUpdate(prevProps) {
                if (prevProps.sku !== this.props.sku) {
                    this.getProduct();
                    const ranking = this.findRanking(this.props.sku);
                    this.setState({
                        ranking: ranking,
                        has_ranking: ranking == 0 ? false : true
                    })
                }
            }

            getProduct = () => {
                const book = this.props.book;
                this.setState({
                    book: book
                })
            }

            handleOnClick = (e, id, sku) => {
                this.storeUi.setCurrentBook(sku);
                //SbC DEPRICATED
                //this.storeBooks.getBookDetailsBySku(sku);
                this.setState({
                    redirect: true
                });
            }

            findRanking = (sku) => {
                const indexOfObject = this.storeBooks.bookList_top60.findIndex((obj) => {
                    if (obj && obj.sku === sku) {
                        return true;
                    }
                    return false;
                });
                return (indexOfObject + 1);
            }
  
            render() {
                const book = this.state.book

                if (this.state.book && this.state.redirect) {
                    const id = content.formatTitle(this.state.book.title);
                    return <Redirect 
                        push to={"/book/" + this.props.sku + "/" + id} 
                    />;
                }

                let aspectClass;
            
                if (book) {
                    const arr = ["CD", "MP3 audio", "Audioboek", "CD-audio", "CD-Rom", "CD pack", "DVD"]
                    const adjustAspect = ui.contains(arr, book.version)
                    if (adjustAspect) { 
                        aspectClass = '--free';
                    }
                }

                return (
                    <div className="block-content">
                        <div className="block-content__column">
                            <div className="block-content--book__cover">
                                <div className="cover__wrapper">
                                    <div className="content --link" onClick={(e) => this.handleOnClick(e, this.props.book.id, this.props.sku)}>
                                        <ImgCover
                                            sku = {this.props.sku}
                                            title = {this.props.book.title}
                                            aspect = {aspectClass}
                                            soldout = {(book && this.storeStock.checkImageCoverTags(book) === 'soldout') ? true : false}
                                        />
                                        {this.storeUi.active_togglenav !== 1 &&
                                            (book && book.sb_edition == 'eigen uitgaven' && book.sku !== '9789079872021' && book.sku !== this.storeGeneral.settings_list.skuSoonAvailable && parseInt(book.stock) !== 2) &&
                                            this.storeVisuals.getCoverTag('sb_edition')
                                        }
                                        
                                        {(this.storeGeneral.settings_list.skuSoonAvailable !== '' && book && book.sku === this.storeGeneral.settings_list.skuSoonAvailable) &&
                                            this.storeVisuals.getCoverTag('soon')
                                        }

                                        {(book && this.storeStock.checkImageCoverTags(book) === 'soldout') &&
                                            this.storeVisuals.getCoverTag('soldout')
                                        }

                                        {(book && this.storeStock.checkImageCoverTags(book) === 'discontinued') &&
                                            this.storeVisuals.getCoverTag('discontinued')
                                        }

                                        {this.storeGeneral.shop === "SHOP_ALL" &&
                                            <BookRanking
                                                sku = {this.props.sku}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                            {!this.props.actions && this.props.actions !== 'hide' && 
                                <div className="block-content--book__cover-info">
                                    <div className="block-content--book__price">
                                        {/* SbC to prevent lagging in loading book covers */}
                                        {(this.props.page !== 'category' && this.props.page !== 'categorie') &&
                                        <div className="block-content--book__preview">
                                            <BlockPreviewPdf
                                                sku = {this.props.sku}
                                                label = {false}
                                            />
                                        </div>
                                        }
                                        
                                        {(book && book.price_incl !== undefined && book.price_incl !== null && book.price_incl !== 0 && book.price_incl !== 99.99) &&
                                            <BookPrice
                                                price = {book && book.price_incl}
                                            />
                                        }

                                        <BlockBookActions
                                            sku = {this.props.sku}
                                            book = {this.state.book}
                                            view = {["wishlist", "cart"]}
                                            display = {"icon"} 
                                        />
                                    </div> 
                                </div>
                             }
                        </div>
                    </div>
                )
            }
        }
    )
)

export default BlockCover;

