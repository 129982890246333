import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";

const BlockBookActions = inject("stores") (
    observer (
        class BlockBookActions extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeUser = this.props.stores.storeUser;
                this.storeOrder = this.props.stores.storeOrder;
                this.storeWishlist = this.props.stores.storeWishlist;
                this.storeStock = this.props.stores.storeStock;
            }

            componentDidMount = () => {

            }

            handleAddToCart = (e, book) => {
                e.preventDefault();
                e.stopPropagation();
                const value = 1;
                const id = (this.storeUser.user_data.is_loggedin) ? this.storeUser.user_data.user_id : 0;

                this.storeOrder.addToCart(this.storeGeneral.shop, id, book, 'add', value);

                this.storeUi.openDialog();
            }

            handleAddToWishlist = (e) => {
                e.preventDefault();
                e.stopPropagation();

                const id = (this.storeUser.user_data.is_loggedin) ? this.storeUser.user_data.user_id : 0;
                this.storeWishlist.addToWishlist(this.props.sku, this.props.book.title, id);
                this.storeUi.openDialogWishList();
            }

            handleRemoveFromWishlist = (e) => {
                e.preventDefault();
                e.stopPropagation();

                const id = (this.storeUser.user_data.is_loggedin) ? this.storeUser.user_data.user_id : 0;
                this.storeWishlist.removeFromWishlist(this.props.sku, id);
            }

            handleMoveToCart = (e, book, sku) => {
                e.preventDefault();
                e.stopPropagation();

                const id = (this.storeUser.user_data.is_loggedin) ? this.storeUser.user_data.user_id : 0;
                this.storeWishlist.removeFromWishlist(sku, id);
                this.storeOrder.addToCart(this.storeGeneral.shop, id, book, 'add', '1');

                this.storeUi.openDialog();
            }
  
            render() {
                const book = this.props.book 
                    ?   this.props.book 
                    :   this.storeBooks.current_book
                    
                const class_display = this.props.display == "icon" ? " btn--icon" : ""
                const display_wishlist = this.props.view.indexOf("wishlist")
                const id = (this.storeUser.user_data.is_loggedin) ? this.storeUser.user_data.user_id : 0;
                
                return (
                    <div 
                        className="block-content--book__cart-actions"
                    >
                        {book && 
                            <React.Fragment>
                            {display_wishlist !== -1 && (
                                this.storeStock.checkStockAvailability('wishlist', book)
                                ?   this.storeWishlist.checkOnWishlist(id, book.sku)
                                    ?   <a 
                                            className={"button button--secundary button--wishlist" + class_display + " --active" } 
                                            onClick={(e) => this.handleRemoveFromWishlist(e)}
                                        >
                                            <span></span>{i18n.t("button.remove_from_wishlist")}
                                        </a>
                                    :   <a 
                                            className={"button button--secundary button--wishlist" + class_display} 
                                            onClick={(e) => this.handleAddToWishlist(e)}
                                        >
                                            {i18n.t("button.add_to_wishlist")}
                                        </a>
                                :   <a 
                                        className={"button button--secundary button--wishlist --disabled" + class_display}
                                    >
                                        {i18n.t("button.add_to_wishlist")}
                                    </a>
                                
                            )}

                            {
                            this.storeStock.checkStockAvailability('order', book)
                            ?   this.storeWishlist.checkOnWishlist(id, book.sku)
                                ?   <a 
                                        className={"button button--primary button--cart" + class_display} 
                                        onClick={(e) => this.handleMoveToCart(e, book, this.props.sku)}
                                    >
                                        {i18n.t("button.move_to_cart")}
                                    </a>
                                :   <a 
                                        className={"button button--primary button--cart" + class_display} 
                                        onClick={(e) => this.handleAddToCart(e, book)}
                                    >
                                        {i18n.t("button.shoppingcart")}
                                    </a>
                            :   <a 
                                    className={"button button--primary button--cart --disabled" + class_display}
                                >
                                    {i18n.t("button.shoppingcart")}
                                </a>
                            }
                            </React.Fragment>
                        }
                    </div>
                )
            }
        }
    )
)

export default BlockBookActions;

