import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Redirect } from 'react-router';
import i18n from "i18next";
import queryString from 'query-string';

import * as ui from '../utilities/ui';
import Template from '../templates/template';

import BlocksBooks from '../components/blocks/blocks-books';
import BlocksTop10 from '../components/blocks/blocks-top10';
import BlockQuote from '../components/block/block-quote';

const Category = inject("stores") (
    observer (
        class Category extends React.PureComponent {

            constructor(props) {
                super(props);
                this.props = props;
                this.state = {
                    category_name: null,
                    cats_main: []
                }
                
                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeSearch = this.props.stores.storeSearch;
                this.storeUser = this.props.stores.storeUser;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeBooksAll = this.props.stores.storeBooksAll;
                this.storeCatalogs = this.props.stores.storeCatalogs;
            }

            componentDidMount = async() => {
            	let url = this.props.location.pathname;
                let splitUrl = url.split('/');
                let return_data;
                let mainCategory;

                // if (this.storeUi.freezeScrolling) {
                //     ui.smoothScrollTo(0, this.storeUi.scrollPos, 500); 
                // } else {
                //     ui.smoothScrollTo(0, 0, 500); 
                // }
                if (this.storeSearch.getSearchViewPos() !== 0) {

                    console.log('SbC page back', this.storeUi.page_view.page, this.storeSearch.getSearchViewPage())
                    // console.log('SbC pos 2', this.storeSearch.getSearchViewPos())
                    this.storeUi.setPageView('page', this.storeSearch.getSearchViewPage());
                    // ui.handleScroll(this.storeSearch.getSearchViewPos());
                } else {
                    ui.smoothScrollTo(0, 0, 500); 
                }

                if (this.props.category == 'category') {
                    const cat_name = this.props.match.params.name;

                    return_data = await this.storeUi.checkShopForCategory(this.props.match.params.name);
                    this.storeGeneral.setActiveShop(return_data.shop);

                    if (return_data.shop !== 'SHOP_SB') {
                        if (this.storeUi.checkFeature('SHOP_ALL')) {
                            return_data = await this.storeUi.checkShopForCategory(cat_name);
    
                            if (return_data !== undefined) {
                                this.storeGeneral.setShop(return_data.shop);
                            }
                        }
                    }

                    if (cat_name !== this.storeUi.app_interface.active_category_name) {
                        if (return_data !== undefined && return_data['shop'] === 'SHOP_ALL') {
                            this.storeUi.setActiveCategory(return_data['category']);
                            this.storeUi.setActiveCategoryDisplayValue(return_data['display_value']);
                        } else {
                            this.storeUi.setActiveCategory(cat_name);    
                        }
                    } else {
                        this.storeUi.setActiveCategory(cat_name);
                    }
                    const cat_details = await this.storeBooksAll.getCategoryDetails(cat_name);
                    if (cat_details !== undefined) {
                        this.storeUi.setActiveCategoryDisplayValue(cat_details.display_value);
                    }

                    //this.storeUi.getBookCountForCategory(cat_name);
                    this.storeBooks.getBooksForCategory(cat_name, this.storeUi.page_view);
                    this.storeUi.setActiveSubCategory('');

                    ui.handleScroll(this.storeSearch.getSearchViewPos());
                }

                if (this.props.category == 'subcategory') {
                    const subcat_name = this.props.match.params.name;
                    
                    return_data = await this.storeUi.checkShopForSubCategory(subcat_name);
                    this.storeGeneral.setActiveShop(return_data.shop);

                    const mainCat = await this.storeUi.getMainCategory(return_data.shop, parseInt(return_data.main_cat));
                    const subCat = return_data.subcategory;
                    ///const count  = await this.storeUi.getBookCountForSubCategory(mainCat.category, subCat);
                    // this.storeCatalogs.setActiveCount(50);

                    if (this.storeGeneral.shop !== 'SHOP_SB') {
                        if (this.storeUi.checkFeature('SHOP_ALL')) {
                            return_data = await this.storeUi.checkShopForSubCategory(subcat_name);
                            if (return_data !== undefined) {
                                this.storeGeneral.setShop(return_data['shop']);
                                this.storeUi.setActiveSubCategory(return_data.subcategory);
                                this.setState({
                                    subcategory_name: return_data.subcategory
                                }) 
                            } else {
                                this.storeGeneral.setShop('SHOP_SB');
                                this.storeUi.setActiveSubCategory(subcat_name);
                            }
                        } else {
                            this.storeGeneral.setShop('SHOP_SB');
                            
                            //let cat_main = await this.storeUi.getMainCategory('SHOP_SB', subcat_name);

                            // if (cat_main.length === 1) {
                            //     this.storeUi.setActiveCategory(cat_main[0].category);
                            //     this.storeUi.setActiveSubCategory(subcat_name);
                            // } else {
                            //     this.storeUi.setActiveCategory('');
                            // }

                            // this.setState({
                            //     subcategory_name: subcat_name,
                            //     cats_main: cat_main
                            // })
                        }
                    } 

                    //SbC if reloaded or direct url
                    if (this.storeBooks.bookList_subcat.length == 0) {
                        return_data = await this.storeUi.checkShopForSubCategory(this.props.match.params.name);
                        this.storeGeneral.setShop(return_data.shop);
                        const cat_id = parseInt(return_data.main_cat);
                        const cat_main = await this.storeUi.getMainCategory('SHOP_ALL', cat_id);
                        mainCategory = cat_main.category;
                        await this.storeBooks.getBooksForSubCategory(mainCategory, subcat_name, this.storeUi.page_view, 1);
                        this.storeUi.setActiveCategory(mainCategory);
                    }
                    //SbC end

                    if (this.storeGeneral.shop === 'SHOP_SB') {
                        let cat_main = await this.storeUi.getMainCategory('SHOP_SB', subcat_name);

                        if (cat_main.length >= 1) {
                            mainCategory = cat_main[0].category;

                            this.storeUi.setActiveCategory(cat_main[0].category);
                            this.storeUi.setActiveCategoryDisplayValue(cat_main[0].display_value);
                            this.storeUi.setActiveSubCategory(subcat_name);

                            await this.storeBooks.getBooksForSubCategory(mainCategory, subcat_name, this.storeUi.page_view, 1);
                        } else {
                            this.storeUi.setActiveCategory('');
                        }

                        this.setState({
                            subcategory_name: subcat_name,
                            cats_main: cat_main,
                            cats_length: cat_main.length
                        })
                    }
                } 
            }

            componentDidUpdate = async () => {
                let cat_name;
                let cat_id;
                let cat_main;
                let return_data;               

                if (this.props.category == 'category') {
                    cat_name = this.props.match.params.name;
                    
                    // if (this.storeGeneral.shop !== 'SHOP_SB') {
                    //     if (this.storeUi.checkFeature('SHOP_ALL')) {
                    //         return_data = await this.storeUi.checkShopForCategory(cat_name);
                    //         if (return_data !== undefined) {
                    //             this.storeGeneral.setShop(return_data['shop']);
                    //         }
                    //     }
                    // }
                    
                    //this.storeUi.getBookCountForCategory(cat_name);

                    const cat_details = await this.storeBooksAll.getCategoryDetails(cat_name);
                    if (cat_details !== undefined) {
                        this.storeUi.setActiveCategory(cat_details.name);
                        this.storeUi.setActiveCategoryDisplayValue(cat_details.display_value);
                    }
                }

                if (this.props.category == 'subcategory') {
                    let subcat_name = this.props.match.params.name;
                    return_data = await this.storeUi.checkShopForSubCategory(this.props.match.params.name);
                    // if (return_data !== undefined ) {
                    //     this.storeGeneral.setShop(return_data.shop);
                    // }

                    if (this.storeGeneral.shop !== 'SHOP_SB') {
                        //SbC causes unnessary reloading in Toplist10 loading from database and subsequent false loading of connecting NUR

                        // if (this.storeUi.checkFeature('SHOP_ALL')) {   
                        //     return_data = await this.storeUi.checkShopForSubCategory(this.props.match.params.name);

                        //     if (return_data !== undefined) {
                        //         subcat_name = return_data.subcategory;
                        //         this.storeUi.setActiveSubCategory(subcat_name);
                        //         this.storeGeneral.setShop(return_data['shop']);
                        //     }
                        // }
                    }

                    if (this.storeGeneral.shop === 'SHOP_ALL') {
                        // if (return_data === undefined) {
                        //     return_data = await this.storeUi.checkShopForSubCategory(this.props.match.params.name);
                        // }
                        cat_id = parseInt(return_data.main_cat);
                        cat_main = await this.storeUi.getMainCategory('SHOP_ALL', cat_id);

                        cat_name = cat_main.display_value;
                    } else {
                        //sBC for via direct url
                        cat_name = this.storeUi.active_category;
                    }

                    if (cat_main && cat_main.length > 1) {
                        if (!this.props.match.params.name.startsWith('top10')) {
                            //SbC DEPRICATED?
                            //this.storeUi.getBookCountForSubCategory(0, this.props.match.params.name);
                        }
                    } else {
                        this.storeUi.setActiveCategoryDisplayValue(cat_name);
                        if (!this.props.match.params.name.startsWith('top10')) {
                            //this.storeUi.getBookCountForSubCategory(cat_name, subcat_name);
                        }
                    }

                    if (subcat_name !== this.state.subcategory_name) {
                        this.setState({
                            subcategory_name: subcat_name,
                            cats_main: cat_main
                        }) 
                    }
                }

                // if (this.storeUi.freezeScrolling) {
                //     ui.smoothScrollTo(0, this.storeUi.scrollPos, 500); 
                // } else {
                //     ui.smoothScrollTo(0, 0, 500); 
                // }
                // this.storeUi.setFreezeScrolling(false);
                // this.storeUi.setFreezePos(0);
            }

            // componentWillUnmount = () => {
            //     const position = window.pageYOffset;
            //     this.storeUi.setFreezePos(position);
            //     this.storeUi.setFreezeScrolling(true);
            // }

            componentWillUnmount = () => {
                const position = window.pageYOffset;
                //this.storeUi.setFreezePos(position);

                this.storeSearch.setSearchViewPos(position);
                this.storeSearch.setSearchViewPage(this.storeUi.page_view.page);
            }
   
            render() {
                const category = this.props.match.params.name;

                return (
                    <Template
                        type = 'default'
                        reset = {false}
                        useScroll = {true}
                    >
                        {this.props.match.params.name !== undefined
                        ?   <div className="content-wrapper__segments">

                                {this.storeGeneral.shop !== 'SHOP_ALL' &&
                                    <BlockQuote 
                                        quote = {this.storeUi.getQuote()}
                                    />
                                }
                                
                                {!this.props.match.params.name.startsWith('top10') &&
                                    <div className="content-wrapper__segment">
                                        {this.props.category == 'category'
                                        ?   <BlocksBooks 
                                                blockCat = {category}
                                                blockTitle = {i18n.t("blocks.books_in_cat.header")}
                                                shop = {this.storeGeneral.shop}
                                                type = "category"
                                                page = "category"
                                            />

                                        :   <BlocksBooks 
                                                blockCat = {category}
                                                blockTitle = {i18n.t("blocks.books_in_cat.header") + " in '" + this.props.match.params.name + "'"}
                                                type = "subcategory"
                                                subcategory = {this.props.match.params.name}
                                                page = "category"
                                                cats_main = {this.state.cats_main}
                                                cats_length = {this.state.cats_length}
                                            />
                                        }
                                    </div>
                                }

                                {this.props.match.params.name.startsWith('top10') &&
                                    <BlocksTop10 
                                        tag = {this.props.match.params.name} 
                                    />
                                }

                                {/* <div className="content-wrapper__segment">
                                    NIET GEVONDEN WAT JE ZOEKT?
                                    ...
                                </div> */}
                            </div>
                        :   <div>
                                <p>Deze categorie is onbekend</p>
                                <h2>Kies hiernaast een categorie</h2>
                            </div>
                        }
                    </Template>
                )
            }
        }
    )
)

export default Category;
