import { observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";
import moment from "moment";

import * as content from '../communicator/content';

class StoreStock {
    onixTranslationFileStock = {}

    constructor(storeRoot) {
        this.storeRoot = storeRoot;
        this.storeGeneral = this.storeRoot.storeGeneral;

        this.listOnyx = 65;
        this.getOnixTranslationFileStock();
    }

    checkStockAvailability(use_case, item) {
        let is_available = true;
        let valid_codes;
        let validCode;
        let hasPrice = true;
        let disabledSkus;
        let disabledSku;
        let itemExpected;
      
        hasPrice = (item.price_incl !== undefined && item.price_incl !== null && item.price_incl !== 0) 
            ?   true
            :   false;

        if (use_case === 'wishlist') {
            valid_codes = [21, 22, 23, 30];
            is_available = (((item.stock !== 0) || (valid_codes.indexOf(parseInt(item.stock_code)) > -1)) && hasPrice)
                ?   true 
                :   false;
        }

        if (use_case === 'order') {
            let diffDate = 0;

            // SbC using supply_date instead of release_date
            let b
            if (item.supply_date) {
                let a = moment();
                if (item.supply_date.date) {
                    b = moment(item.supply_date.date);
                } else {
                    b = moment(item.supply_date);
                }
                diffDate = b.diff(a, 'days');
            } 
            
            let maxDaysExpected = this.storeGeneral.settings_list["maxDaysExpected"];

            itemExpected = (item.supply_date && diffDate >= 0 && (diffDate < parseInt(maxDaysExpected)))
                ?   true
                :   false
            ;

            let inStock = (item.stock === 1 || item.stock === "1") 
                ?   true 
                :   false
            ;

            inStock = (parseInt(item.stock_code) === 32 && (item.stock === 1 || item.stock === "1") && !itemExpected) 
                ?   false
                :   inStock
            ;

            inStock = ((item.stock === 0 || item.stock === "0") && itemExpected) 
                ?   true
                :   inStock
            ;

            valid_codes = [21, 22, 23, 30, 32];
            validCode = ((valid_codes.indexOf(parseInt(item.stock_code)) > -1)) 
                ?   true 
                :   false
            ;

            disabledSkus = [];
            disabledSku = ((disabledSkus.indexOf(parseInt(item.sku)) > -1)) 
                ?   true 
                :   false
            ;

            is_available = (((inStock && validCode && hasPrice) || (inStock && itemExpected && hasPrice) || (itemExpected && hasPrice)) && !disabledSku) 
                ?   true 
                :   false
            ;
        }

        //SbC temp necessary if no stock code available
        if (this.storeGeneral.shop == 'SHOP_SB' && is_available == false && item.stock_code == null) {
            if (((item.stock === 1 || item.stock === '1') && hasPrice)  && !disabledSku) {
                is_available = true;
            }
        }

        // SbC temp necessary if no stock code available for books SB in SHOP_ALL
        if (is_available == false) {
            if (((item.stock === 1 || item.stock === '1') && hasPrice) && !disabledSku && item.stock_code == null) {
                is_available = true;
            }
        }

        return is_available;
    }

    checkImageCoverTags(item) {
        let tag = '';

        const stock = parseInt(item.stock);
        const stockCode = parseInt(item.stock_code);

        switch (stockCode) {
            case 40:
            case 51:
                tag = 'soldout';
            break;
        }

        switch (stock) {
            case 2:
                tag = 'soldout';
                break;
            case 3:
                tag = 'discontinued';
            break;
        }

        return tag;
    }

    async getOnixTranslationFileStock() {
        let result;

        try {
            const returnData = await content.getOnixTranslationFile();
            runInAction(() => {
                result = returnData.data;
                this.onixTranslationFileStock = result[1]['Code'];
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async getOnyxTranslation(list, stockCode) {
        //let result = '';
        const idx = this.onixTranslationFileStock.findIndex(item => parseInt(item.CodeValue) === parseInt(stockCode));
        const result = this.onixTranslationFileStock[idx]['CodeDescription'];
        
        return result;
    }

    getFeedbackStock(book) {
        let feedback = '';
        let stock;
        let stockCode;

        const hasStock = this.checkStock(book);
        const hasStockCode = this.checkStockCode(book);
        const hasDeliveryTime = this.checkDeliveryTime(book);
    
        if (book.price_incl === undefined || book.price_incl === null || parseInt(book.price_incl) === 0) {
            feedback = 'Niet of niet meer in assortiment';
        };

        if (book.price_incl !== undefined && book.price_incl !== null && parseInt(book.price_incl) !== 0) {
            if (hasStockCode) {
                stockCode = parseInt(this.getStockCode(book));
                feedback = this.getFeedbackStockCode(book, stockCode)

            } else {
                stock = parseInt(this.getStock(book));
                feedback = this.getFeedbackStockSimple(book, stock)
            }
        }

        return feedback;
    }

    checkStock(book) {
        let value = false;
            if (parseInt(book.stock === 1)) {
                value = true;
            }
        return value;
    };

    checkStockCode(book) {
        let value = false;
        if (book.stock_code && book.stock_code !== null) {
            value = true;
        }
        return value;
    };

    checkDeliveryTime(book) {
        let value = false;
        if (book.delivery !== '' && book.delivery !== null) {
            value = true;
        }
        return value;
    };

    checkExpected(book) {
        let value = false;
        // SbC check SupplyDate
        
        return value;
    };

    checkReleaseDate = (releaseDate) => {
        let isAfter = false;
        var currentDate = moment().format("DD MMMM YYYY");

        if (releaseDate && releaseDate.date) {
            if (moment(currentDate).isSameOrBefore(moment(releaseDate.date))) {
                isAfter = true;
            }
        } else {
            if (moment(currentDate).isSameOrBefore(moment(releaseDate))) {
                isAfter = true;
            }
        }

        return isAfter;
    }

    getStock(book) {
        const value = book.stock;

        return value;
    };

    getStockCode(book) {
        const value = book.stock_code;

        return value;
    };

    getDeliveryTime(book) {
        let value = book.delivery;
        let podValue = parseInt(value);

        if (!isNaN(value)) {
            const start = podValue + 2;
            const end = podValue + 4;
            value = 'Levertijd: ' + start + '-' + end + ' werkdagen';
        } else {
            if (value && !value.startsWith("Levertijd")) {
                value = "Levertijd: " + value;
            };
        }

        if (!book.delivery) {
            value = 99;
        }

        return value;
    };

    getReleaseDate(book) {
        let value = book.release_date.date;

        return value;
    };

    getSupplyDate(book) {
        let value = book.supply_date.date;

        return value;
    };

    getFeedbackStockCode = async(book, stockCode) => {
        let value = '';
        let result;

        if (stockCode === 21) {
            value = 'Levertijd: 1-3 werkdagen';
            value = await this.getOnyxTranslation(this.listOnyx, stockCode);
            if (this.checkDeliveryTime(book)) {
                result = this.getDeliveryTime(book);
                if (result !== 99) {
                    value = result;
                }
            }
        }
        
        if (parseInt(stockCode) === 22) {
            //SbC TO ORDER
            value = 'Levertijd: 7-9 werkdagen';
            value = await this.getOnyxTranslation(this.listOnyx, stockCode);
            if (this.checkDeliveryTime(book)) {
                result = this.getDeliveryTime(book);
                if (result !== 99) {
                    value = result;
                }
            }
        }

        if (stockCode === 23) {
            //SbC POD
            value = 'Levertijd: 1-3 werkdagen';
            value = await this.getOnyxTranslation(this.listOnyx, stockCode);
            if (this.checkDeliveryTime(book)) {
                result = this.getDeliveryTime(book);
                if (result !== 99) {
                    value = result;
                }
            }
        }

        if (stockCode === 30) {
            //SbC In bestelling
            value = 'Levertijd: 1-3 werkdagen';
            value = await this.getOnyxTranslation(this.listOnyx, stockCode);
            if (this.checkDeliveryTime(book)) {
                result = this.getDeliveryTime(book);
                if (result !== 99) {
                    value = result;
                }
            }
        }

        if (stockCode === 10) {
            value = 'Nog niet verschenen';
            value = await this.getOnyxTranslation(this.listOnyx, stockCode);

            let isAfter;
            if (book.supply_date && book.supply_date.date) {
                isAfter = this.checkReleaseDate(book.supply_date);
                if (isAfter) {
                    value = value + ', verwacht op ' + moment(book.supply_date.date).format("DD MMMM YYYY");
                }
            } else {
                isAfter = this.checkReleaseDate(book.supply_date);
                if (isAfter) {
                    value = value + ', verwacht op ' + moment(book.supply_date).format("DD MMMM YYYY");
                }
            }
        }

        if (stockCode === 32) {
            value = 'In herdruk';
            value = await this.getOnyxTranslation(this.listOnyx, stockCode);

            let isAfter;
            if (book.supply_date.date) {
                isAfter = this.checkReleaseDate(book.supply_date);
                if (isAfter) {
                    value = value + ', verwacht op ' + moment(book.supply_date.date).format("DD MMMM YYYY");
                }
            } else {
                isAfter = this.checkReleaseDate(book.supply_date);
                if (isAfter) {
                    value = value + ', verwacht op ' + moment(book.supply_date).format("DD MMMM YYYY");
                }
            }
        }

        if (stockCode !== 10 &&
            stockCode !== 21 &&
            stockCode !== 22 &&
            stockCode !== 23 &&
            stockCode !== 30 &&
            stockCode !== 32) {
            value = await this.getOnyxTranslation(this.listOnyx, stockCode);
        }

        return value;
    }

    getFeedbackStockSimple(book, stock) {
        let value = '';

        if (stock === 0) {
            value = '(Tijdelijk) niet leverbaar';
            if (book.idx !== null && this.checkExpected(book)) {
                value = 'Binnen 7 dagen weer leverbaar';
            }
        }

        if (stock === 1) {
            value = 'Levertijd: 1-3 werkdagen';
            if (book.id !== null && this.checkDeliveryTime(book)) {
                value = this.getDeliveryTime(book);
            }
        }

        if (stock === 2) {
            value = 'Uitverkocht';
        }

        if (stock === 3) {
            value = 'Niet meer leverbaar';
        }

        return value;
    }
}

decorate(StoreStock, {
    checkStockAvailability: action,
    checkImageCoverTags: action,
    getOnyxTranslation: action,
    getFeedbackStock: action
})

export default StoreStock;