import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Redirect } from 'react-router';
import moment from "moment";

import BookPrice from '../../elements/book-price';
import BlockBookActions from './block-book-actions';
import ImgCover from '../../elements/img-cover';
import BookAuthor from './book-author';
import BookRanking from '../../elements/book-ranking';

const BlockListitem = inject("stores") (
    observer (
        class BlockListitem extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.state = {
                    redirect: false
                }

                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeUser = this.props.stores.storeUser;
                this.storeOrder = this.props.stores.storeOrder;
                this.storeStock = this.props.stores.storeStock;
                this.storeVisuals = this.props.stores.storeVisuals;
            }

            async componentDidMount() {
                this.getProduct();
            }

            async componentDidUpdate(prevProps) {
                if (prevProps.sku !== this.props.sku) {
                    this.getProduct();
                }
            }

            getProduct = () => {
                const book = this.props.book;
                this.setState({
                    book: book
                })
            }

            handleOnClick = (e, id, sku) => {
                this.storeUi.setCurrentBook(sku);
                //this.storeBooks.getBookDetailsBySku(sku);
                this.setState({
                    redirect: true
                });
            }
  
            render() {
                if (this.state.redirect) {
                    return <Redirect 
                        push to={"/book/" + this.props.sku} 
                    />;
                }

                let current_book = this.state.book;

                return (
                    <div className="block-content block-content--compact">
                        <div className="block-content--info">
                            <div className="block-content--compact__image" onClick={(e) => this.handleOnClick(e, this.props.book.id, this.props.sku)}>
                                <ImgCover
                                    sku = {this.props.sku}
                                    soldout = {(current_book && this.storeStock.checkImageCoverTags(current_book) === 'soldout') 
                                        ?   true 
                                        :   false
                                    }
                                />
                               
                                {(current_book && this.storeStock.checkImageCoverTags(current_book) === 'soldout') &&
                                    this.storeVisuals.getCoverTag('soldout')
                                }

                                {(current_book && parseInt(current_book.stock) === 3) &&
                                    <span className="cover__discontinued">Niet meer leverbaar</span>
                                }
                                       
                                {this.storeGeneral.shop === "SHOP_ALL" &&
                                    <BookRanking
                                        sku = {this.props.sku}
                                    />
                                }
                            </div>
                            <div className="block-content--compact__title" onClick={(e) => this.handleOnClick(e, this.props.book.id, this.props.sku)}>
                                <span
                                    dangerouslySetInnerHTML={{ __html: current_book && current_book.title}} 
                                />
                                {current_book && current_book.subtitle !== null &&
                                    <div 
                                        className="block-content--compact__subtitle"
                                        dangerouslySetInnerHTML={{ __html: current_book.subtitle}} 
                                    />
                                }
                                {(this.storeUi.page_view.sorting === 'author_lastname' && (current_book && current_book.author_lastname !== null)) &&
                                    <div className="block-content--compact__subtitle">
                                        <BookAuthor
                                            author = {''}
                                            author_firstname = {current_book ? current_book.author_firstname : ''}
                                            author_prefix = {current_book ? current_book.author_prefix : ''}
                                            author_lastname = {current_book ? current_book.author_lastname : ''}
                                            is_author_page = {false}
                                            pushHistory = {this.pushHistory}
                                        />
                                    </div>
                                }
                                {this.storeUi.page_view.sorting === 'release_date' &&
                                    <div className="block-content--compact__subtitle">
                                        Verschijningsdatum: {current_book && current_book.release_date && moment(current_book.release_date.date).format('DD MMMM YYYY')}
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="block-content--actions">
                            <div className="block-content--book__price">
                                {(current_book && current_book.price_incl !== undefined && current_book.price_incl !== null && current_book.price_incl !== 0 && current_book.price_incl !== 99.99) &&
                                    <BookPrice
                                        price = {current_book && current_book.price_incl}
                                    />
                                }
                                <BlockBookActions
                                    sku = {this.props.sku}
                                    book = {current_book}
                                    view = {["wishlist", "cart"]}
                                    display = {"icon"} 
                                />
                            </div>
                        </div>
                    </div>
                )
            }
        }
    )
)

export default BlockListitem;

