import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";

import * as ui from '../utilities/ui';

const ButtonPagingFBack = inject("stores") (
    observer (
        class ButtonPagingFBack extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.storeUi = this.props.stores.storeUi;

                this.state = {
                    active: true,
                    btnClass: "--active"
                }
            }

            componentDidMount = () => {
            }

            componentDidUpdate = (prevProps) => {
            }

            pagingFBack = async(e) => {
                this.setPage();
            }

            setPage = () => {
                let count;
                let added = 6;

                let page;
                let from;
                let to;

                if (this.state.active) {
                    //SbC check for min
                    count = this.storeUi.page_view.rangeFrom - 6;
                    if (count <= 1) {
                        added = this.storeUi.page_view.rangeFrom;

                        page = 1;
                        from = 1;
                        to = this.props.displayed;
                    } else {
                        page = this.props.page - added;
                        from = this.storeUi.page_view.rangeFrom - added;
                        to = this.storeUi.page_view.rangeTo - added;
                    }

                    this.storeUi.setPageView('page', page);
                    this.storeUi.setPageView('rangeFrom', from);
                    this.storeUi.setPageView('rangeTo', to);

                    this.props.setPage(page);

                    if (count >= this.props.pages) {
                        this.setState({
                            active: true,
                            btnClass: "--active"
                        })
                    }
                }

                ui.handleScroll('top');
                this.storeUi.setFreezeScrolling(false);
            }


            render() {  
                //const total = this.storeUi.page_view.total;
                let total = this.storeUi.count_total;
                const inview = this.storeUi.page_view.inview;
                let pages = Math.ceil(parseInt(total) / parseInt(inview));

                const btnClass = this.props.pages < 6 
                    ?   '--inactive' 
                    :   this.storeUi.page_view.rangeFrom === 1
                        ?   '--inactive'
                        :   '--active'

                return (
                    <div 
                        className = {"paging-back-wrapper " + btnClass} 
                        onClick = {(e) => this.pagingFBack(e)}
                    >
                        <div className="paging-back-wrapper__icon" />
                        <div className="paging-back-wrapper__icon" />
                    </div>
                )
            }
        }
    )
)

export default withRouter(ButtonPagingFBack);
