import React, { Component } from 'react';
import { observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

class StoreVisuals {

    constructor() {
    }

    getCoverTag(value) {
        let tag = '';

        switch (value) {
            case 'soldout':
                tag = <span className="cover__soldout">Uitverkocht</span>;
                break;
            case 'discountinued':
                tag = <span className="cover__soldout">Niet meer leverbaar</span>;
                break;
            case 'soon':
                tag = <span className="cover__soon">Binnenkort</span>;
                break;
            case 'sb_edition':
                tag = <span className="cover__tag">Eigen uitgave</span>;
                break;
        }

        return tag;
    }
}

decorate(StoreVisuals, {
    getCoverTag: action
})

export default StoreVisuals;