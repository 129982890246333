import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";

import Template from '../templates/template';

import BlockAuthor from '../components/block/block-author';
import BlocksBooks from '../components/blocks/blocks-books';

const Author = inject("stores") (
    observer (
        class Author extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeUser = this.props.stores.storeUser;
                this.storeBooks = this.props.stores.storeBooks;

                this.state = {
                    author: ''
                }
            }

            componentDidMount = async() => {
                this.handleScroll();
                let author = this.props.match.params.id;
                let shop;
                let books;
                let count;

                this.storeUi.setCurrentAuthor(this.props.match.params.id);

                if (this.storeUi.checkFeature('SHOP_ALL')) {  
                    shop = this.storeGeneral.shop;
                    if (shop != 'SHOP_ALL') {
                        shop = 'SHOP_SB';
                    } else {
                        shop = 'SHOP_ALL';
                    }
                } else {
                    shop = 'SHOP_SB';
                }
                
                books = await this.storeBooks.getBooksForAuthor(this.storeUi.page_view, this.props.match.params.id, '', '', shop); 
                //count = await this.storeUi.returnBookCountForAuthorSimple('SHOP_ALL', author);
                count = await this.storeUi.getBookCountForAuthor('SHOP_ALL', author);

                if (this.storeBooks.authorBooksList == null) {
                    books = await this.storeBooks.getBooksForAuthor(this.storeUi.page_view, this.props.match.params.id, '', '', 'SHOP_ALL'); 
                    count = await this.storeUi.returnBookCountForAuthorSimple('SHOP_ALL', author);
                }

                this.setState({
                    author: author,
                    count: count
                })
            }

            componentDidUpdate = async(prevProps, prevState) => {
                let shop;
                let books;
                let count;
                this.handleScroll();
                
                if (this.props.match.params.id !== this.state.author) { 
                    const author = this.props.match.params.id;
                   
                    if (this.storeUi.checkFeature('SHOP_ALL')) {  
                        shop = this.storeGeneral.shop;
                        if (shop != 'SHOP_ALL') {
                            shop = 'SHOP_SB';
                        } else {
                            shop = 'SHOP_ALL';
                        }
                    } else {
                        shop = 'SHOP_SB';
                    }

                    books = await this.storeBooks.getBooksForAuthor(this.storeUi.page_view, this.props.match.params.id, '', '', shop); 
                    //count = await this.storeUi.returnBookCountForAuthorSimple('SHOP_ALL', author);
                    count = await this.storeUi.getBookCountForAuthor('SHOP_ALL', author);

                    this.storeUi.setPageView('total', count);
                    this.storeUi.setCountTotal(count);

                    this.setState({
                        author: author,
                        count: count
                    })
                }
            }

            handleScroll = () => {
                window.scrollTo(0, 0);
            }
    
            render() {
                return (
                    <Template
                        type = {'default'}
                        header = {"all_books_from"}
                        personalized = {this.props.match.params.id}
                    >
                        <div className="content-segment-clean">
                            {/* <p>{i18n.t("page_subheaders.search_results_count")}: {this.storeUi.count_total}</p> */}
                            <p>{i18n.t("page_subheaders.search_results_count")}: {this.state.count}</p>
                        </div>

                        <div className="content-wrapper__content">
                            {this.storeBooks.authorBooksList && this.storeBooks.authorBooksList.length !== 0
                            ?   <div className="author-info">                                   
                                    <div className="content-wrapper__segment">
                                        <BlocksBooks 
                                            type = "author"
                                            page = "author"
                                            author = {this.props.match.params.id}
                                        />
                                    </div>
                                </div>
                            :   <div className="author-info">
                                    <p>Deze auteur is onbekend</p>
                                </div>
                            }
                        </div>
                    </Template>
                )
            }
        }
    )
)

export default Author;
