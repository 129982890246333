import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import { Link } from 'react-router-dom';
import i18n from "i18next";
import { withTranslation } from 'react-i18next';

import * as content from '../utilities/content';

import ImgCover from '../elements/img-cover';

const ImgCoverRecently = inject("stores") (
    observer (
        class ImgCoverRecently extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeHistory = this.props.stores.storeHistory;
                this.storeUser = this.props.stores.storeUser;
                this.storeStock = this.props.stores.storeStock;
                this.storeVisuals = this.props.stores.storeVisuals;

                this.arrTypes = [
                    'Boeken (Nederlands)',
                    'Boeken (Duits)',
                    'Boeken (Spaans)',
                    'Boeken (Engels)',
                    'Audioboeken (Engels)',
                    'Audioboeken (Nederlands)'
                ];

                this.state = {
                    book: {}
                }
            }

            componentDidMount = async() => {
                let shop;
                let book;

                if (this.storeUi.checkFeature('SHOP_ALL')) {
                    shop = 'SHOP_ALL';
                } else {
                    shop = 'SHOP_SB';
                }

                book = await this.storeBooks.returnBookDetailsBySku(shop, this.props.sku);
                if (!book) {
                    shop = 'SHOP_SB';
                    book = await this.storeBooks.returnBookDetailsBySku(shop, this.props.sku);
                }
                
                const media = this.checkMediaType(book.media);

                this.setState({
                    media: media,
                    book: book
                })
            }

            checkMediaType = (value) => {
                let type = "book";

                if (value !== null) {
                    if (this.arrTypes.includes(value)) {
                        type = "book";
                    } else {
                        type = "product"
                    };
                }

                return type;
            }

            handleOnClick = async(e, sku) => {
                e.preventDefault();
                let shop;

                // if (this.storeUi.checkFeature('SHOP_ALL')) {
                //     shop = 'SHOP_ALL';
                // } else {
                //     shop = 'SHOP_SB';
                // }
                shop = await this.storeUi.checkShopForSku(sku);

                this.storeGeneral.setShop(shop);
                this.storeGeneral.setActiveShop(shop);

                const book = await this.storeBooks.returnBookDetailsBySku(shop, sku);
                const id = content.formatTitle(book.title);

                let url;
                if (parseInt(this.storeGeneral.settings_list.seoTitles) === 1) {
                    url = "/book/" + sku + "/" + id;
                } else {
                    url = "/book/" + sku;
                }

                this.props.history.push({
                    sku: sku,
                    pathname: url
                })
            }
  
            render() {
                return (
                    <li className={"block block--book --link block-media--" + this.state.media} onClick={(e) => this.handleOnClick(e, this.state.book.sku)}>
                        <div className={"block-media--" + this.state.media + "__content"}> 
                            <ImgCover
                                sku = {this.props.sku}
                                title = {this.state.book && this.state.book.title}
                                soldout = {(this.state.book && this.storeStock.checkImageCoverTags(this.state.book) === 'soldout') ? true : false}
                            />

                            {this.storeUi.active_togglenav !== 1 &&
                                (this.state.book && this.state.book.sb_edition == 'eigen uitgaven' && this.state.book.sku !== this.storeGeneral.settings_list.skuSoonAvailable && parseInt(this.state.book.stock) !== 2) &&
                                this.storeVisuals.getCoverTag('sb_edition')
                            }

                            {(this.state.book && this.storeStock.checkImageCoverTags(this.state.book) === 'soldout') &&
                                this.storeVisuals.getCoverTag('soldout')
                            }
                        </div>
                    </li>             
                )
            }
        }
    )
)

export default withTranslation()(withRouter(ImgCoverRecently));

