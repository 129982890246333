import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withRouter } from "react-router-dom";

import ImgCover from '../../elements/img-cover';
import BookLang from '../../elements/book-lang';
import BlockBookStock from '../../components/block/block-book-stock';

import * as utilities from '../../utilities/formats';

const SearchOverlayItem = inject("stores") (
    observer (
        class SearchOverlayItem extends Component {

            constructor(props) {
                super(props);
                this.props = props;

                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;
                this.storeStock = this.props.stores.storeStock;
                this.storeVisuals = this.props.stores.storeVisuals;

                this.state = {
                    formattedTitle: this.props.item.title,
                    elevatedHeader: 'aanbevolen'
                }
            }

            componentDidMount = async() => {
                let elevatedHeader;
                if (this.props.item["[elevated]"]) {
                    elevatedHeader = await this.getElevatedHeader();

                    this.setState({
                        elevatedHeader: elevatedHeader
                    })
                }
            }

            componentDidUpdate = async(prevProps) => {
                let elevatedHeader;
                if (prevProps.item.sku !== this.props.item.sku) {
                    if (this.props.item["[elevated]"]) {
                        elevatedHeader = await this.getElevatedHeader();
    
                        this.setState({
                            elevatedHeader: elevatedHeader
                        })
                    }
                }
            }

            getElevatedHeader = async() => {
                let elevatedHeader = 'aanbevolen';
                let header = await this.storeUi.getElevatedSku(this.props.item.sku);
                
                if (header['elevatedHeader'] !== null) {
                    elevatedHeader = header['elevatedHeader'];
                }
                
                return elevatedHeader;
            }

            handleOnClick = (result) => {
                this.storeUi.setCurrentBook(result.sku);
                //this.storeBooks.getBookDetailsBySku(result.sku);

                if (this.props.shop) {
                    this.storeGeneral.setActiveShop(this.props.shop);
                }

                if (this.props.shop) {
                    if (this.props.shop === 'SB') {
                        this.storeUi.setActiveToggleNav(2);
                        this.storeGeneral.setActiveShop('SHOP_SB');
                    }
                    if (this.props.shop === 'ALL') {
                        this.storeUi.setActiveToggleNav(3);
                        this.storeGeneral.setActiveShop('SHOP_ALL');
                    }
                }
                
                let url;
                if (parseInt(this.storeGeneral.settings_list.seoTitles) === 1) {
                    //url = "/book/" + result.sku + "/" + id;
                    url = "/book/" + result.sku;
                } else {
                    url = "/book/" + result.sku;
                }
                this.props.history.push({
                    sku: result.sku,
                    pathname: url
                })

                this.storeUi.setSearchOverlay(false);
            }

            constructAuthor(firstname, prefix, lastname) {
                let author = '';

                if (this.props.item.author_firstname) {
                    author = this.props.item.author_firstname;
                    if (this.props.item.author_prefix) {
                        author = author + ' ' + this.props.item.author_prefix;
                    }
                    if (this.props.item.author_lastname) {
                        author = author + ' ' + this.props.item.author_lastname;
                    }
                } else {
                    if (this.props.item.author_lastname) {
                        author = this.props.item.author_lastname;
                    }
                    if (this.props.item.author_prefix) {
                        author = this.props.item.author_prefix + ' ' + author;
                    }
                }

                return author;
            }

            render() {
                return (
                    <li 
                        className={(this.props.item["[elevated]"] ? "--elevated" : "") + " --link"}
                        onClick={(e) => this.handleOnClick(this.props.item)}
                        data-content={this.state.elevatedHeader}
                    >
                        <div 
                            className="search-item"
                        >
                            <div 
                                className="search-item__img"
                            >
                                <ImgCover
                                    sku = {this.props.item.sku}
                                    soldout = {(this.props.item && this.storeStock.checkImageCoverTags(this.props.item) === 'soldout') 
                                        ?   true 
                                        :   false
                                    }
                                />
                                {(this.props.item && this.storeStock.checkImageCoverTags(this.props.item) === 'soldout') &&
                                    this.storeVisuals.getCoverTag('soldout')
                                }
                            </div>
                            <div 
                                className="search-item__data"
                            >
                                <div>
                                    {this.props.item.title &&
                                        <span
                                            className="search-item__data--title"
                                            dangerouslySetInnerHTML={{ __html: utilities.capitalizeTitle(this.props.item.title)}}
                                        />
                                    }
                                    <span
                                        dangerouslySetInnerHTML={{ __html: this.constructAuthor(this.props.item.author_firstname, this.props.item.author_prefix, this.props.item.author_lastname)}}
                                    />
                                </div>
                                <div 
                                    className="search-item__specs"
                                >
                                    <ul className="block--specs-summary__list">
                                        <li className="block--specs-summary__listitem">
                                            <BookLang
                                                book = {this.props.item}
                                            />
                                        </li>
                                        <li className="block--specs-summary__listitem">{this.props.item.version}</li>
                                        <li className="block--specs-summary__listitem">ISBN: {this.props.item.sku}</li>
                                    </ul>

                                    <BlockBookStock 
                                        sku = {this.props.item.sku}
                                        book = {this.props.item}
                                        stock_code = {this.props.item.stock_code}
                                    ></BlockBookStock>
                                </div>
                            </div>
                            
                        </div>
                    </li>
                )
            }
        }
    )
)

export default withRouter(SearchOverlayItem);
