import { observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";

import countries from "i18n-iso-countries";
import * as sort from '../utilities/sort';

import * as account from '../communicator/account';
import * as authors from '../communicator/authors';
import * as books from '../communicator/books';
import * as booklists from '../communicator/book-lists';
import * as categories from '../communicator/categories';
import * as catalog from '../communicator/catalog';
import * as content from '../communicator/content';
import * as forms from '../communicator/forms';
import * as payment from '../communicator/payment';
import * as products from '../communicator/products';
import * as discounts from '../communicator/discounts';
import * as search from '../communicator/search';
import * as settings from '../communicator/settings';
import * as tools from '../communicator/tools';
import { act } from "react-dom/test-utils";

class StoreUi {
    app_interface = { 
        active_genre: "0",
        active_category: "0",
        active_subcats: '',
        active_search_segment: 1,
        active_tab: 'profile',
        cookie_alert: true,
        current_book: "0",
        current_author: 0,
        dialog_type: '',
        login_direct: false,
        use_case: "inshop",
        use_case_order: '',
        partner_code: 0,
        nav_mobile: false,
        height_footer: 0,
        height_header: 0,
        isDialogFade: false,
        isDialogOpen: false,
        isDialogContentOpen: false,
        isDialogDeleteOpen: false,
        isDialogWishListOpen: false,
        isDialogFeedbackFormOpen: false,
        isDialogNewsletterOpen: false,
        isDialogNotificationOpen: false,
        isDialogVideoOpen: false,
        urlNewsletterOpen: '',
        reload: false,
        return_state: '',
        view_account: 'overview'
    }

    active_product = {}

    active_nav = 0
    active_sbnav = 0
    active_togglenav = 0
    active_subnav = 0
    
    active_category = ''
    active_category_display_value = ''
    active_subcategory = ""
    active_subcategory_display_value = ''
    active_subcats = ""
    active_product_group = ""
    active_subgroup = ""
    active_nur = '0'
    active_language = ''

    active_video = 0
    active_video_playing = false
    active_video_location = 'list'
    active_video_cc = 'disabled'

    featureAll = false;

    foldout = {
        top10: false,
        top60: false
    }
    
    isDialogContentOpen = false
    isDialogVideoOpen = false

    carousel_home = ''
    carousel_home_class = '--play'
    content_component = ''
    content_text = []
    cookie_duration = 16000
    count_total = 0
    features_list =[]
    form_feedback = {}
    searchFeedback = false;
    freezePaging = false
    freezeScrolling = false
    list_languages = []
    notifications = []
    social_links = []
    payment_providers = []
    newsletter_active = {}
    newsletters = []
    userDiscounts = []
    settings = {}
    settings_list = []
    stockPriority = 1;
    quote = {}
    banner = {}
    scrollPos = 0
    search_intentions = []
    search_input_focus = false
    search_overlay = false
    search_results = []
    search_results_simple = []
    search_results_simple_own = []
    search_results_simple_sb = []
    search_results_simple_all = []
    search_results_own = []
    search_results_sb = []
    search_results_all = []
    search_results_alternative = []
    search_results_newsletter = []
    search_value = ''
    search_value_saved = ''
    search_count = 0
    search_count_alternative = 0
    search_count_own = 0
    search_count_sb = 0
    search_count_all = 0
    videos = []
    order_to_process = {}
    templateView = ''

    page_view = {
        total: 0,
        sorting: 'ID',
        order: 'DESC',
        page: 1,
        inview: 25,
        rangeFrom: 1,
        rangeTo: 6
    }

    account_nav = [{
        id: 1,
        name: 'account',
        path: "/account",
        use: "all"
    }]

    main_nav = [{
        id: 1,
        name: 'books_new',
        path: "/new-books",
        use: "all",
        show: ["SHOP_SB"] 
    },{
        id: 2,
        name: 'ebooks',
        path: "/ebooks",
        use: "all",
        show: ["SHOP_SB"] 
    },{
        id: 3,
        name: 'new_this_month',
        path: "/new-books/month",
        use: "all",
        show: ["SHOP_ALL"] 
    },{
        id: 4,
        name: 'new_this_week',
        path: "/new-books/week",
        use: "all",
        show: ["SHOP_ALL"] 
    },{
        id: 5,
        name: 'top10',
        path: "/category/top10",
        use: "all",
        show: ["SHOP_ALL"] 
    }
    ]

    sub_nav = [{
        id: 1,
        name: 'videos',
        path: "/videos",
        use: "all",
        show: ["SHOP_SB","SHOP_ALL"] 
    },{
        id: 2,
        name: 'partners',
        path: "/partners",
        use: "partner",
        value: false
    },{
        id: 3,
        name: 'partner',
        path: "/partner",
        use: "partner",
        value: true
    },{
        id: 4,
        name: 'jobs',
        path: "/jobs",
        use: "all",
        show: ["SHOP_SB","SHOP_ALL"] 
    },{
        id: 5,
        name: 'sitemap',
        path: "/sitemap",
        use: "all",
        show: ["SHOP_SB","SHOP_ALL"] 
    },{
        id: 6,
        name: 'contact',
        path: "/address",
        use: "all",
        show: ["SHOP_SB","SHOP_ALL"] 
    }]

    toggle_nav = [{
        id: 1,
        name: 'toggle_books_own',
        path: "/our-publications",
        use: "all",
        show: ["SHOP_SB"],
        tooltip: "tt-nav-own",
        showTip: false,
        showExplanation: false 
    },{
        id: 2,
        name: 'toggle_books_sb',
        path: "/",
        use: "all",
        tooltip: "tt-nav-sb",
        showTip: false,
        showExplanation: false
    },{
        id: 3,
        name: 'toggle_books_all',
        path: "/all-books",
        use: "all",
        tooltip: "tt-nav-all",
        showTip: false,
        showExplanation: false   
    }]

    constructor(storeRoot) {
        this.storeRoot = storeRoot;
        this.storeGeneral = this.storeRoot.storeGeneral;
        this.storeAuth = this.storeRoot.storeAuth;
        this.storeCatalogs = this.storeRoot.storeCatalogs;
        this.getLanguages();
        this.getSettings();
        this.getFeatures();
        this.getPaymentProviders();
        this.checkNotifications();
        this.getTooltips();
    }

    async getSettings() {
        let settingsList;
        try {
            const returnData = await settings.getSettings();
            runInAction(() => {
                settingsList = returnData.data;
                settingsList.map(setting => {
                    this.settings_list[setting.SettingName] = setting.SettingValue;
                })
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async getFeatures() {
        try {
            const returnData = await settings.getFeatures();
            runInAction(() => {
                this.features_list = returnData.data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async getLanguages() {
        try {
            const returnData = await settings.getLanguages();
            runInAction(() => {
                this.list_languages = returnData.data;
                this.languages = returnData.data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async translateNURtoValue(nur) {
        let display_value = nur;
        try {
            const returnData = await catalog.translateNURtoValue(nur);
            runInAction(() => {
                display_value = returnData.data[0].display_value;
                return display_value;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return display_value;
    }

    checkFeature(sku, is_loggedin, access_level) {
        let hasFeature = false; 
        let accessLevel = this.storeAuth.user.access_level;
        let isLoggedin = this.storeAuth.user.is_loggedin;
        
        const idx = this.features_list
            .findIndex(feature => feature.sku === sku);
        
        if (idx !== -1) {
            if (parseInt(this.features_list[idx].access_level) === 0) {
                hasFeature = true; 
            } else {
                if (isLoggedin && (parseInt(accessLevel) >= parseInt(this.features_list[idx].access_level))) {
                    hasFeature = true; 
                } else {
                    hasFeature = false; 
                }
            }
        } else {
            hasFeature = false;
        }

        if (sku === 'SHOP_ALL') {
            hasFeature = true; 
        }
        
        return hasFeature;
    }

    async checkIfNURCategory(cat) {
        let cat_nur = 0;
        try {
            const returnData = await catalog.checkIfNURCategory(cat);
            runInAction(() => {
                cat_nur = parseInt(returnData.data);
                return cat_nur;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return cat_nur;
    }

    openDialog = () => {
        this.app_interface.isDialogOpen = true;
    }

    openDialogContent = () => {
        this.app_interface.isDialogContentOpen = true;
        this.isDialogContentOpen = true;
    }

    openDialogVideo = () => {
        this.app_interface.isDialogVideoOpen = true;
        this.isDialogVideoOpen = true;
    }

    openDialogDelete = (product) => {
        this.active_product = product;
        this.app_interface.isDialogDeleteOpen = true;
    }

    openDialogFeedbackForm = (type) => {
        this.app_interface.dialog_type = type;
        this.app_interface.isDialogFeedbackFormOpen = true;
    }

    openDialogWishList = (url) => {
        this.app_interface.isDialogWishListOpen = true;
    }

    openDialogNewsletter = (url) => {
        this.app_interface.urlNewsletterOpen = url;
        this.app_interface.isDialogNewsletterOpen = true;
    }

    closeDialog = () => {
        this.app_interface.isDialogOpen = false;
    }

    closeDialogContent = () => {
        this.app_interface.isDialogContentOpen = false;
        this.isDialogContentOpen = false;
    }

    closeDialogVideo = () => {
        this.app_interface.isDialogVideoOpen = false;
        this.isDialogVideoOpen = false;
        //this.active_video_location = 'list';
    }

    closeDialogDelete = () => {
        this.active_product = {};
        this.app_interface.isDialogDeleteOpen = false;
    }

    closeDialogFeedbackForm = () => {
        this.app_interface.isDialogFeedbackFormOpen = false;
    }

    closeDialogWishList = () => {
        // this.app_interface.isDialogFade = true;
        // setTimeout(() => { 
        //     this.app_interface.isDialogFade = false;
        //     this.app_interface.isDialogWishListOpen = false;
        // }, 2000);
        this.app_interface.isDialogWishListOpen = false;
    }

    closeDialogNewsletter = () => {
        document.body.classList.remove('dialog-open');
        this.app_interface.isDialogNewsletterOpen = false;
    }

    checkCookies() {
        let cookies_alert = true;
        if (localStorage.getItem('SB_COOKIES')) {
            cookies_alert = JSON.parse(localStorage.getItem('SB_COOKIES')) == true ? false : true;
        }

        return cookies_alert;
    }

    setCookies() {
        localStorage.setItem('SB_COOKIES', true);
    }

    setFeatureAll(bool) {
        this.featureAll = bool;
    }

    setFreezePaging(bool) {
        this.freezePaging = bool;
    }

    setFreezeScrolling(bool) {
        this.freezeScrolling = bool;
    }

    setFreezePos(pos) {
        this.scrollPos = pos;
    }

    async getTooltips(type, idx) {
        this.toggle_nav.map(async(nav, id) => {
            try {
                const returnData = await this.getTooltip(nav.tooltip);
                runInAction(() => {
                    this.toggle_nav[id].title = returnData[0].title;
                    this.toggle_nav[id].text = returnData[0].text;
                    this.toggle_nav[id].textLong = returnData[0].textLong;
                })
            } catch (error) {
                runInAction(() => {
                    this.state = "error"
                })
            }
        })
    }

    showTooltip(type, idx) {
        if (type === 'tip') {
            this.toggle_nav.map((nav, i) => {
                this.toggle_nav[i].showExplanation = false;
            })
            if (idx === 0) {
                this.toggle_nav.map((nav, i) => {
                    this.toggle_nav[i].showTip = false;
                })
            } else {
                this.toggle_nav.map((nav, id) => {
                    if (id === (idx - 1)) {
                        this.toggle_nav[id].showTip = true;
                    } else {
                        this.toggle_nav[id].showTip = false;
                    }
                })
            }
        } 
        
        if (type === 'explanation') {
            this.toggle_nav.map((nav, i) => {
                this.toggle_nav[i].showTip = false;
            })
            if (idx === 0) {
                this.toggle_nav.map((nav, i) => {
                    this.toggle_nav[i].showExplanation = false;
                })
            } else {
                this.toggle_nav.map((nav, id) => {
                    if (id === (idx - 1)) {
                        this.toggle_nav[id].showExplanation = true;
                    } else {
                        this.toggle_nav[id].showExplanation = false;
                    }
                })
            }
        }
    }

    async copyCoverForSharing(sku) {
        try {
            const returnData = await content.copyCover(sku);
            runInAction(() => {
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async checkNotifications() {
        try {
            const returnData = await content.getNotifications();
            runInAction(() => {
                this.notifications = returnData.data;
                if (this.notifications.length > 0) {
                    this.openDialogNotifications();
                }
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    closeDialogNotifications = () => {
        this.app_interface.isDialogNotificationOpen = false;
    }

    openDialogNotifications = () => {
        this.app_interface.isDialogNotificationOpen = true;
    }

    setReturnState(return_state) {
        this.app_interface.return_state = return_state;
    }

    async getQuote(id) {
        const id_quote = id ? id : null;
        let quote;
        try {
            const returnData = await content.getQuote(id_quote);
            runInAction(() => {
                this.quote = returnData.data[0];
                quote = returnData.data[0];
                return quote;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return quote;
    }

    async getBanner() {
        try {
            const returnData = await content.getBanner();
            runInAction(() => {
                this.banner = returnData.data[0];
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async getContentText(page) {
        let content_text;
        try {
            const returnData = await content.getContentText(page);
            runInAction(() => {
                this.content_text = JSON.parse(returnData.data).data;
                content_text = JSON.parse(returnData.data).data;
                return content_text;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return content_text;
    }

    async getNewsletters() {
        let newsletters;
        try {
            const returnData = await content.getNewsletters();
            runInAction(() => {
                this.newsletters = returnData.data;
                newsletters = returnData.data;
                return newsletters;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return newsletters;
    }

    async getPaymentProviders() {
        const bankTrans = {
            id: 'BANKTRANS',
            description: 'Overboeking',
            displayValue: 'Overboeking'
        }
        try {
            const returnData = await payment.getPaymentProviders();
            runInAction(() => {
                this.payment_providers = returnData.data;

                this.payment_providers.map((provider, i) => {
                    this.payment_providers[i].displayValue = this.payment_providers[i].description;
                    this.payment_providers[i].value = i;
                })

                //SbC check if handmatige overschijving is aanwezig
                const idx = this.payment_providers
                    .findIndex((provider) => provider.id === 'BANKTRANS');
                if (idx === -1) {                  
                    this.payment_providers.push(bankTrans);
                }
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async getUserDiscounts() {
        try {
            const returnData = await discounts.getUserDiscounts();
            runInAction(() => {
                this.userDiscounts = returnData.data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    getProductDiscount() {
        return 30;
    }

    async getVideos(section) {
        let view = 'videos';
        if (section) {
            view = section;
        }
        try {
            const returnData = await content.getVideos(view);
            runInAction(() => {
                this.videos = returnData.data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async getVideoById(id) {
        let video;
        try {
            const returnData = await content.getVideoById(id);
            runInAction(() => {
                video = returnData.data;
                return video;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return video;
    }

    async getVideoByReference(reference) {
        let video;
        try {
            const returnData = await content.getVideoByReference(reference);
            runInAction(() => {
                video = returnData.data;
                return video;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return video;
    }

    async getVideosByLabel(view, label) {
        try {
            const returnData = await content.getVideosByLabel(view, label);
            runInAction(() => {
                this.videos = returnData.data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async checkVATNumber(countryCode, nrVAT) {
        let vat_validation;
        this.storeGeneral.startLoader();
        try {
            const returnData = await tools.checkVATNumber(countryCode, nrVAT);
            runInAction(() => {
                vat_validation = returnData;
                return vat_validation;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {
            this.storeGeneral.stopLoader();
        }
        return vat_validation;
    }

    async getElevatedSku(sku) {
        let results = [];
        let returnData;
        try {
            returnData = await search.getElevatedSku(sku);

            runInAction(() => {
                results = returnData.data[0];
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {
            //this.storeGeneral.stopLoader();
        }

        return results;
    }

    async getSearchResultsSimple(shop, term, section, page_view, alternative) {
        let results = [];
        let returnData;
        //let stockPriority = this.stockPriority;
        let stockPriority = 1;

        if (term !== '' && term !== undefined) {
            //this.storeGeneral.startLoader();
            const skuSoon = this.storeGeneral.settings_list.skuSoonAvailable;
            this.setPageView('page', 1);

            try {
                if (shop === 'SHOP_SB') {
                    returnData = await search.getSearchResultsSB(shop, term, section, page_view, stockPriority, skuSoon);
                } else {
                    returnData = await search.getSearchResults(shop, term, section, page_view, stockPriority, skuSoon);
                }
                runInAction(() => {
                    results = returnData.data;
                    //if (alternative) {
                        this.search_results_alternative = returnData.data;
                        //this.search_results_simple = [];
                    //} else {
                        this.search_results_simple = returnData.data;
                        //this.search_results_alternative = [];
                    //}

                    // if (shop === 'SHOP_SB') {
                    //     if (section == 'sb') {
                    //         this.search_results_simple_own = returnData.data;
                    //     } else {
                    //         this.search_results_simple_sb = returnData.data;
                    //     }
                    // } else {
                    //     this.search_results_simple_all = returnData.data;
                    // }

                    return results;
                })
            } catch (error) {
                runInAction(() => {
                    this.state = "error"
                })
            } finally {
                //this.storeGeneral.stopLoader();
            }
        }

        return results;
    }

    getSearchResultsSimpleWithAbort(shop, term, section, page_view, alternative, controller, signal) {
        let results = [];
        let returnData;
        //let stockPriority = this.stockPriority;
        let stockPriority = 1;

        if (term !== '' && term !== undefined) {
            //this.storeGeneral.startLoader();
            const skuSoon = this.storeGeneral.settings_list.skuSoonAvailable;
            this.setPageView('page', 1);
            this.setPageView('inview', 100);

            try {
                if (shop === 'SHOP_SB') {
                    returnData = search.getSearchResultsSB(shop, term, section, page_view, stockPriority, skuSoon);
                } else {
                    returnData = search.getSearchResultsWithAbort(shop, term, section, page_view, stockPriority, skuSoon, controller, signal);
                }
                runInAction(() => {
                    results = returnData;
                    //if (alternative) {
                        //this.search_results_alternative = returnData.data;
                        //this.search_results_simple = [];
                    //} else {
                        //this.search_results_simple = returnData.data;
                        //this.search_results_alternative = [];
                    //}

                    // if (results !== undefined && results !== null) {
                    //     if (shop === 'SHOP_SB') {
                    //         if (section == 'sb') {
                    //             this.search_results_simple_own = returnData.data;
                    //         } else {
                    //             this.search_results_simple_sb = returnData.data;
                    //         }
                    //     } else {
                    //         this.search_results_simple_all = returnData.data;
                    //     }
                    // }

                    return results;
                })
            } catch (error) {
                runInAction(() => {
                    this.state = "error"
                })
            } finally {
                //this.storeGeneral.stopLoader();
            }
        }

        return results;
    }

    setSearchResultsSimple = (shop, section, results, value) => {
        let bestMatch = false;

        if (results !== null && results !== undefined) {
            if (shop === 'SHOP_SB') {
                if (section === 'sb') {
                    this.search_results_simple_own = results;
                } else {
                    this.search_results_simple_sb = results;
                }
            } else {
                this.search_results_simple_all = results;
            }

            if (results.length > 0) {
                if (results[0] && results[0].title !== undefined) {
                    this.searchTopItemTitle = results[0].title.toLowerCase();
                    this.searchTopItemTitleSku = results[0].sku;
                    this.searchTopItemScore = results[0].score;
                    this.search_results_simple_all = results;
                } else {
                    this.searchTopItemTitleSku = '';
                }

                results.map(item => {
                    let title = '';
                    if (item.title) {
                        title = item.title.toLowerCase();
                    } 
                
                    if (!bestMatch && (title === value.toLowerCase())) {
                        this.searchTopItemTitle = title;
                        this.searchTopItemTitleSku = item.sku;
                        bestMatch = true;
                    } else {
                        if (!bestMatch && (title.startsWith(value.toLowerCase()) || title.endsWith(value.toLowerCase()) || title.includes(value.toLowerCase()))) {
                            this.searchTopItemTitle = title;
                            this.searchTopItemTitleSku = item.sku;
                            bestMatch = true;
                        }
                    }
                })
            }
        }
    }

    getSearchResultsSimple = async() => {
        return this.search_results_simple_all;
    }

    async getSearchResults(term, section, page_view) {
        //const shop = this.storeGeneral.shop;
        const shop = 'SHOP_ALL';
        const skuSoon = this.storeGeneral.settings_list.skuSoonAvailable;
        this.storeGeneral.startLoader();

        let stockPriority = this.stockPriority;

        try {
            const returnData = await search.getSearchResults(shop, term, section, page_view, stockPriority, skuSoon);
            runInAction(() => {
                //this.search_results = returnData.data;
                this.search_results_all = returnData.data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {
            this.storeGeneral.stopLoader();
        }
    }

    async getSearchResultsSB(term, section, page_view) {
        const shop = 'SHOP_SB';
        const skuSoon = this.storeGeneral.settings_list.skuSoonAvailable;
        this.storeGeneral.startLoader();

        let stockPriority = this.stockPriority;

        try {
            const returnData = await search.getSearchResultsSB(shop, term, section, page_view, stockPriority, skuSoon);
            runInAction(() => {
                //this.search_results = returnData.data;
                if (section === 'sb') {
                    this.search_results_own = returnData.data;
                } else {
                    this.search_results_sb = returnData.data;
                }
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {
            this.storeGeneral.stopLoader();
        }
    }

    async getSearchResultsNewsletter(value) {
        this.storeGeneral.startLoader();
        let result;

        try {
            const returnData = await search.getSearchResultsNewsletter(value);
            runInAction(() => {
                this.search_results_newsletter = returnData.data;
                result = returnData.data;

                console.log('SbC search', returnData)
                
                return result;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {
            this.storeGeneral.stopLoader();
        }

        return result;
    }

    async saveSearchQuery(term) {
        try {
            const returnData = await search.saveSearchQuery(term);
            runInAction(() => {
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {
        }
    }

    async getSearchIntentions(term) {
        try {
            const returnData = await search.getSearchIntentions(term);
            runInAction(() => {
                this.search_intentions = returnData.data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

    }

    async getTooltip(sku) {
        let tooltip = {};
        try {
            const returnData = await content.getTooltip(sku);
            runInAction(() => {
                tooltip = returnData.data;
                return tooltip;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return tooltip;
    }

    async checkFile(isbn, type) {
        let result = [{'error': 'no file'}];

        //SbC TEMP NOT USED
        //use only if sarch is <= 25

        if (this.storeGeneral.shop === 'SHOP_SB') {
            try {
                const returnData = await tools.checkFile(isbn, type);
                runInAction(() => {
                    result = returnData.data;
                    return result;
                })
            } catch (error) {
                runInAction(() => {
                    this.state = "error"
                })
            }
        }

        return result;
    }

    async checkPdfExtra(isbn) {
        let result = [{'error': 'no file'}];

        try {
            const returnData = await tools.checkPdfExtra(isbn);
            runInAction(() => {
                result = returnData.data;
                return result;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return result;
    }

    checkFileWithAbort(isbn, type, controller, signal) {
        let result = [{'error': 'no file'}];

        try {
            const returnData = tools.checkFileWithAbort(isbn, type, controller, signal);
            runInAction(() => {
                result = returnData;
                return result;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return result;
    }

    clearFormFeedback() {
        this.form_feedback = {};
    }

    setFormFeedback(type, msg) {
        if (type === 'success') {
            this.form_feedback = {
                succes: true,
                msg: msg
            };
        } else {
            this.form_feedback = {
                error: true,
                msg: msg
            };
        }
    }

    redirectISBN(searchString) {
        //const params = queryString.parse(searchString)
    }

    setAccountView(view) {
        this.app_interface.view_account = view;
    }

    setActiveMainNav(id) {
        this.active_nav = id;
        this.app_interface.active_search_segment = id;
    }

    setActiveSubNav(id) {
        this.active_subnav = id;
    }

    setActiveSbNav(id) {
        this.active_sbnav = id;
    }

    setActiveToggleNav(id) {
        this.active_togglenav = id;
    }

    setActiveGenre(id) {
        this.app_interface.active_genre = id;
    }

    setActiveCategory(name) {
        if (name !== this.active_category) {
            this.active_subcats = name;
            this.app_interface.active_category_name = name;
            this.active_category = name;
        }
    }

    setActiveCategoryDisplayValue(display_value) {
        this.active_category_display_value = display_value;
    }

    setActiveSubCats(name) {
        if (name === this.active_category && this.active_subcats !== '') {
            this.active_subcats = '';
        } else {
            this.active_subcats = name;
        }
    }

    setActiveNUR(nur) {
        this.active_nur = nur;
    }

    // setShowSubCats(name, show) {
    //     if (show) {
    //         this.app_interface.active_subcats = name;
    //     } else {
    //         console.log('SbC click cat show?', name, this.app_interface.active_category_name, this.app_interface.active_subcats)
    //         if (name !== this.app_interface.active_category_name || this.app_interface.active_subcats == '') {
    //             this.app_interface.active_subcats = name;
    //         } else {
    //             if (name !== this.app_interface.active_subcats) {
    //                 this.app_interface.active_subcats = name;
    //             } else {
    //                 this.app_interface.active_subcats = 'x';
    //             }
    //         }
    //     }
    // }


    setActiveLanguage(abbr) {
        this.active_language = abbr;
    }

    setShowSubCats(name, show) {
        if (show) {
            //if (this.app_interface.active_subcats === '') {
                this.app_interface.active_subcats = name;
            //} else {
                //this.app_interface.active_subcats = '';
            //}
        } else {
            this.app_interface.active_subcats = '';
        }
    }

    setActiveNav(name) {
        switch (name) { 
            case 'default':
                this.setActiveCategory('default');
                this.setActiveProductGroup('default');
                this.setCurrentAuthor('default');
                break;
            case 'authors':
                this.setCurrentAuthor(999);
                this.setActiveProductGroup(99);
                break;
            default:
        }
        
    }

    setActiveSubCategory(name) {
        this.app_interface.active_subcategory_name = name;
        this.active_subcategory = name;
    }

    setActiveProductGroup(name) {
        this.app_interface.active_product_group = name;
        this.active_product_group = name;
    }

    setActiveSubGroup(name) {
        this.app_interface.active_subgroup = name;
        this.active_subgroup = name;
    }

    setActiveSearchSegment(id) {
        this.app_interface.active_search_segment = id;
    }

    setContentComponent(comp) {
        this.content_component = comp;
        return true;
    }

    setCurrentAuthor(id) {
        this.app_interface.current_author = id;
    }

    setCurrentBook(sku) {
        this.app_interface.current_book = sku;
    }

    setDirectLogin(login) {
        this.app_interface.login_direct = login;
    }

    setHeightFooter(num) {
        this.app_interface.height_footer = num;
    }

    setHeightHeader(num) {
        this.app_interface.height_header = num;
    }

    setPageView(key, value) {
        this.page_view[key] = value;

        if (key == 'page' && value == 1) {
            this.page_view['rangeFrom'] = 1;
            this.page_view['rangeTo'] = 6;
        }
    }

    setPartnerCode(code) {
        this.app_interface.partner_code = code;
    }

    setTemplateView(view) {
        if (view) {
            this.templateView = view;
        } else {
            this.templateView = '';
        }
    }

    setUseCase(type) {
        this.app_interface.use_case = type;
    }

    setUseCaseOrder(type) {
        this.app_interface.use_case_order = type;
    }

    setActiveTab(name) {
        this.app_interface.active_tab = name;
    }

    setActiveVideo(idx) {
        this.active_video = idx;
    }

    setActiveVideoPlaying(value) {
        this.active_video_playing = value;
    }

    setActiveVideoLocation(value) {
        this.active_video_location = value;
    }

    setActiveVideoDialog = (value) => {
        this.isDialogVideoOpen = value;
        if (value === false) {
            this.setActiveVideoLocation('list');
        } else {
            this.setActiveVideoLocation('popup');
        }
    }

    setActiveVideoCC(cc) {
        this.active_video_cc = cc;
    }

    setOrderToProcess(order_id, order_total) {
        this.order_to_process = {
            id: order_id,
            total: order_total,
            payment_id: 'IDEAL',
            payment_method_id: 0,
            issuer_code: 9999
        };
    }

    setOrderToProcessKey(key, value) {
        this.order_to_process[key] = value;
    }

    async retrievePassword(formControls, sms_count) {
        this.storeGeneral.startLoader();
        try {
            let returnData = await account.retrievePassword(formControls.email.value, formControls.sms.value, sms_count);
            runInAction(() => {
                console.log('SbC returnData', returnData)
                if (!('error' in returnData.msg)) {
                    this.form_feedback = returnData.msg.fb;
                } else {
                    this.form_feedback = returnData.msg;
                } 
            })
        } catch (error) {
            runInAction(() => {
            })
        } finally {
            this.storeGeneral.stopLoader();
        }
    }

    async checkPassword(id, email, pw) {
        let valid;

        this.storeGeneral.startLoader();
        try {
            let returnData = await account.checkPw(id, email, pw);
            runInAction(() => {
                valid = returnData.data
                return valid;
            })
        } catch (error) {
            runInAction(() => {
            })
        } finally {
            this.storeGeneral.stopLoader();
        }
        return valid;
    }

    async sendFormReview(isbn, book_title, form_values, upload_file, loggedin, account_id) {
        const book_data = {
            isbn: isbn,
            title: book_title
        }
        const form_data = {
            from_firstname: form_values['firstname'].value,
            from_lastname: form_values['lastname'].value,
            from_email: form_values['email'].value,
            //profession: form_values['profession'].value,
            //website: form_values['website'].value,
            profile: form_values['profile'].value,
            review_title: form_values['review_title'].value,
            review: form_values['review'].value,
            account_id: account_id,
            show_firstname: form_values['show_firstname'].value,
            show_lastname: form_values['show_lastname'].value,
            show_profession: form_values['show_profession'].value,
            show_website: form_values['show_website'].value,
            show_profile: form_values['show_profile'].value
        }

        this.storeGeneral.startLoader();
        try {
            const returnData = await forms.sendFormReview('review', book_data, form_data, upload_file);
            runInAction(() => {
                this.form_feedback = returnData.data[0];
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {
            this.storeGeneral.stopLoader();
        }
    }

    async addReview(isbn, book_title, form_values, upload_file, loggedin, account_id) {
        const book_data = {
            isbn: isbn,
            title: book_title
        }
        const form_data = {
            from_firstname: form_values['firstname'].value,
            from_lastname: form_values['lastname'].value,
            from_email: form_values['email'].value,
            //profession: form_values['profession'].value,
            //website: form_values['website'].value,
            profile: form_values['profile'].value,
            review_title: form_values['review_title'].value,
            review: form_values['review'].value,
            account_id: account_id,
            show_firstname: form_values['show_firstname'].value,
            show_lastname: form_values['show_lastname'].value,
            show_profession: form_values['show_profession'].value,
            show_website: form_values['show_website'].value,
            show_profile: form_values['show_profile'].value
        }
        try {
            const returnData = await catalog.addReview('review', book_data, form_data, upload_file);
            runInAction(() => {

            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {

        }
    }

    async sendFormPress(form_values, to_email, book) {
        const form_data = {
            to_email: to_email,
            from_firstname: form_values['firstname'].value,
            from_lastname: form_values['lastname'].value,
            from_email: form_values['email'].value,
            phonenr: form_values['phonenr'].value,
            address: form_values['address'].value,
            housenr: form_values['housenr'].value,
            zipcode: form_values['zipcode'].value,
            city: form_values['city'].value,
            media: form_values['media'].value,
            requested_sku: book.sku,
            requested_title: book.title,
            content: form_values['remark'].value
        }
        this.storeGeneral.startLoader();
        try {
            const returnData = await forms.sendFormPress('press', form_data);
            runInAction(() => {
                this.form_feedback = returnData.data[0];
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {
            this.storeGeneral.stopLoader();
        }
    }

    async sendFormSendToFriend(form_values, book) {
        let relations = [];

        form_values['relations'].map((relation, i) => {
            let new_rel = {};
            new_rel.firstname = relation.firstname.value;
            new_rel.lastname = relation.lastname.value;
            new_rel.email = relation.email.value;
            relations.push(new_rel);
        });

        const form_data = {
            to_relations: relations,
            from_firstname: form_values['firstname'].value,
            from_lastname: form_values['lastname'].value,
            from_email: form_values['email'].value,
            content: form_values['message'].value,
            partner_code: form_values['partner_code'].value
        }
        this.storeGeneral.startLoader();
        try {
            const returnData = await forms.sendFormSendToFriend('sendtofriend', form_data, book);
            runInAction(() => {
                this.form_feedback = returnData.data[0];
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {
            this.storeGeneral.stopLoader();
        }
    }

    setNavMobile(state) {
        if (state != undefined) {
            this.app_interface.nav_mobile = state;
        } else {
            this.app_interface.nav_mobile = !this.app_interface.nav_mobile;
        }
    }

    async checkShopForCategory(cat) {
        let data;
        if (cat !== undefined) {
            try {
                const returnData = await categories.checkShopForCategory(cat);
                runInAction(() => {
                    if (Array.isArray(returnData.data)) {
                        data = returnData.data[0];
                    } else {
                        data = returnData.data;
                    }
                    this.active_category_display_value = data.display_value;
                    return data;
                })
            } catch (error) {
                runInAction(() => {
                    this.state = "error"
                })
            }
        } else {
            data = "SHOP_SB";
        }

        return data;
    }

    async checkShopForSubCategory(cat) {
        let data;
        if (cat !== undefined) {
            try {
                const returnData = await categories.checkShopForSubCategory(cat);
                runInAction(() => {
                    if (Array.isArray(returnData.data)) {
                        data = returnData.data[0];
                    } else {
                        data = returnData.data;
                    }
                    this.active_subcategory_display_value = data.display_value;
                    return data;
                })
            } catch (error) {
                runInAction(() => {
                    this.state = "error"
                })
            }
        } else {
            data = "SHOP_SB";
        }

        return data;
    }

    async checkShopForSku(sku) {
        let data;
        try {
            const returnData = await books.checkShopForSku(sku);
            runInAction(() => {
                data = returnData.data.shop;
                this.storeGeneral.setShop(data);
                return data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return data;
    }

    async getShopForSku(sku) {
        let data;
        try {
            const returnData = await books.checkShopForSku(sku);
            runInAction(() => {
                data = returnData.data.shop;
                return data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return data;
    }

    /*
    * SbC replace by direct fetch from table
    */
    async getBookCountForCategory(cat) {
        const shop = this.storeGeneral.shop;

        let stockPriority = this.stockPriority;
        
        try {
            const returnData = await booklists.getBookCountForCategory(shop, cat, stockPriority);
            runInAction(() => {
                this.count_total = returnData.data;
                this.setPageView('total', this.count_total);
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async getProductCountForCategory(cat) {
        try {
            const returnData = await products.getProductCountForCategory(cat);
            runInAction(() => {
                this.count_total = returnData.data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async getProductCountForSubGroup(group, subgroup) {
        try {
            const returnData = await products.getProductCountForSubGroup(group, subgroup);
            runInAction(() => {
                this.count_total = returnData.data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async getBookCountForSubCategory(shop, cat, subcat) {
        let result;

        let stockPriority = this.stockPriority;

        try {
            const returnData = await booklists.getBookCountForSubCategory(shop, cat, subcat, stockPriority);
            runInAction(() => {
                this.count_total = returnData.data;
                result = returnData.data
                this.setPageView('total', this.count_total);

                return result;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return result;
    }

    async getBookCountForLanguage(lang) {
        const shop = this.storeGeneral.shop;
        try {
            const returnData = await booklists.getBookCountForLanguage(shop, lang);
            runInAction(() => {
                this.count_total = returnData.data;
                this.count_total_lang = returnData.data;
                this.setPageView('total', returnData.data);
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async getBookCountForBooksNew(view, page_view) {
        const shop = this.storeGeneral.shop;
        let count;
        try {
            const returnData = await booklists.getBookCountForBooksNew(shop, view, page_view);
            runInAction(() => {
                this.count_total = returnData.data;
                count = returnData.data;
                return count;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return count;
    }

    // async getBookCountForAuthor(shop, str) {
    //     // try {
    //     //     const returnData = await authors.getBookCountForAuthor2(shop, str);
    //     //     runInAction(() => {        
    //     //         this.count_total = returnData.data;
    //     //         this.setPageView('total', this.count_total);
    //     //     })
    //     // } catch (error) {
    //     //     runInAction(() => {
    //     //         this.state = "error"
    //     //     })
    //     // } finally {

    //     // }
    // }

    async getBookCountForBooksSB(shop, page_view) {
        try {
            const returnData = await booklists.getBookCountForBooksSB(shop, page_view);
            runInAction(() => {   
                if (returnData.data !== null) {
                    this.count_total = returnData.data;  
                    this.setPageView('total', this.count_total);
                } else {
                    this.count_total = 0;
                }
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {

        }
    }

    getBookCountForAuthor = async(shop, str) => {
        let count;
        
        try {
            const returnData = await authors.getBookCountForAuthorSimple(shop, str);
            runInAction(() => {      
                count = returnData.data;

                //SbC REVIEW
                //gives wrong count in count summaries in 

                //this.count_total = returnData.data;
                //this.setPageView('total', this.count_total);
                this.setPageView('total', returnData.data);
                return count;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {

        }
        return count;
    }

    returnBookCountForAuthorSimple(shop, str) {
        let count = 0;

        const author = str.split(" ");
        const firstname = author[0];
        const lastname = author[author.length - 1];

        const list = this.storeCatalogs.listAuthors;

        const keyToCheck1 = "firstname";
        const keyToCheck2 = "lastname";
        const value1ToMatch = firstname;
        const value2ToMatch = lastname;

        if (list) {
            list.forEach(obj => {
                if (obj[keyToCheck1] === value1ToMatch && obj[keyToCheck2] === value2ToMatch) {
                    count = obj['bookCountTotal'];
                } 
            });
        }

        return count;
    }

    returnBookCountForCategorySimple(shop, cat, stock) {
        let count = 0;

        const list = this.storeCatalogs.listCategories;
        const valueToMatch = cat;

        if (shop === 'SHOP_SB') {
            const keyToCheck = "category";

            if (list) {
                list.forEach(obj => {
                    if (obj[keyToCheck] === valueToMatch) {
                        if (stock === true || parseInt(stock) === 1) {
                            count = obj['bookCountSBStock'];
                        } else {
                            count = obj['bookCountSB'];
                        }
                    } 
                });
            }
        }

        if (shop === 'SHOP_ALL') {
            const keyToCheck = "description";

            if (list) {
                list.forEach(obj => {
                    if (obj[keyToCheck] === valueToMatch) {
                        if (stock === true || parseInt(stock) === 1) {
                            count = obj['bookCountAllStock'];
                        } else {
                            count = obj['bookCountAll'];
                        }
                    } 
                });
            }
        }

        this.setCountTotal(count);

        return count;
    }

    async getMainCategory(shop, cat) {
        let cat_main;
        try {
            const returnData = await categories.getMainCategory(shop, cat);
            runInAction(() => {
                cat_main = returnData.data;
                
                this.active_category = cat_main[0].category;
                this.active_category_display_value = cat_main[0].display_value;
                this.active_subcats = cat_main[0].category;

                return cat_main;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return cat_main;
    }

    async returnMainCategory(shop, cat) {
        let cat_main;
        try {
            const returnData = await categories.getMainCategory(shop, cat);
            runInAction(() => {
                cat_main = returnData.data;
                return cat_main;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return cat_main;
    }

    getCarousel() {
        let value;
        if (localStorage.getItem('SB_CAROUSEL')) {
            value = localStorage.getItem('SB_CAROUSEL');
        } else {
            value = 'play';
            this.setCarousel(value);
        }
        this.carousel_home = value;
        this.carousel_home_class = "--" + value;
        return value;
    }

    setCarousel(play) {
        this.carousel_home = play;
        this.carousel_home_class = "--" + play;
        localStorage.setItem('SB_CAROUSEL', play);
    }

    async getSearchCount(search_value, section, alternative) {
        if (search_value !== '' && search_value !== undefined) {
            const skuSoon = this.storeGeneral.settings_list.skuSoonAvailable;

            try {
                const returnData = await search.getSearchCount(search_value, section, this.page_view, skuSoon);
                runInAction(() => {
                    // if (alternative) {
                    //     this.search_count_alternative = returnData.data;
                    //     this.search_count = null;
                    // } else {
                    //     this.search_count_alternative = null;
                    //     this.search_count = returnData.data;                    
                    // }
                    if (returnData.data !== null) {
                        //if (section === this.storeGeneral.searchShop) {
                            //this.count_total = returnData.data;
                        //}
                        this.search_count_all = returnData.data;
                        this.setPageView('total', returnData.data)
                    } else {
                        this.count_total = 0;
                        this.search_count_all = 0;
                    }                   
                })
            } catch (error) {
                runInAction(() => {
                    this.state = "error"
                })
            }
        } else {
            this.count_total = 0;
            this.search_value = '';
        }
    }

    async getSearchCountSB(search_value, section, alternative) {
        if (search_value !== '' && search_value !== null && search_value !== undefined) {
            let value;
            //SbC check...
            if (search_value) {
                value = search_value;
            } else {
                value = this.search_value;
            }
            //** */

            value = this.search_value;
            const skuSoon = this.storeGeneral.settings_list.skuSoonAvailable;

            try {
                const returnData = await search.getSearchCountSB(value, section, this.page_view, skuSoon);
                runInAction(() => {
                    if (alternative) {
                        this.search_count_alternative = returnData.data;
                        this.search_count = null;
                    } else {
                        this.search_count_alternative = null;
                        this.search_count = returnData.data;
                    }
                    if (returnData.data !== null) {
                        this.count_total = returnData.data;
                        if (section === 'sb') {
                            this.search_count_own = returnData.data;
                        } else {
                            this.search_count_sb = returnData.data;
                        }
                        this.count_total = returnData.data;
                        this.setPageView('total', returnData.data)
                    } else {
                        if (section === 'sb') {
                            this.search_count_own = 0;
                        } else {
                            this.search_count_sb = 0;
                        }
                    }
                })
            } catch (error) {
                runInAction(() => {
                    this.state = "error"
                })
            }
        } else {
            this.search_count = 0;
            this.count_total = 0;
            this.search_value = '';
        }
    }

    setStockPriority(value) {
        this.stockPriority = value;
    }

    setSearchValue(value) {
        this.search_value = value.trim();
    }

    setSearchOverlay(value) {
        const env = process.env.REACT_APP_ENV;
        this.search_overlay = value;

        if (env === 'prod' && !value && this.search_value !== '' && this.search_value !== this.search_value_saved) {
            this.search_value_saved = this.search_value;
            this.saveSearchQuery(this.search_value);
        }
    }

    resetSearchOverlayValues() {
        //this.search_results_alternative = null;
        //this.search_count_alternative = 0;
    }

    setSearchInputFocus(value) {
        this.search_input_focus = value;
    }

    setSearchInputRef(value) {
        this.search_inputRef = value;
    }

    setSearchInputFieldRef(value) {
        this.search_inputfieldRef = value;
    }

    setSearchOverlayRef(value) {
        this.search_overlayRef = value;
    }

    setWrapperRef(value) {
        this.wrapperRef = value;
    }

    setSearchFeedback(bool) {
        this.searchFeedback = bool;
    }

    setFoldout(list, value) {
        switch(list) {
            case "top10":
                this.foldout.top10 = value;
                break;
            case "top60":
                this.foldout.top60 = value
            default:
        }
    }

    async getShortUrl(sku, params) {
        let url;
        try {
            const returnData = await content.getShortUrl(sku, params);
            runInAction(() => {
                url = returnData;
                return url;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return url;
    }

    setActiveNewsletter(newsletter) {
        this.newsletter_active = newsletter;
    }

    reloadOrderLines(reload) {
        this.app_interface.reload = reload;
    }

    setCountTotal(value) {
        this.count_total = value;
    }
}


decorate(StoreUi, {
    app_interface: observable,
    active_category: observable,
    active_category_display_value: observable,
    active_language: observable,
    active_nav: observable,
    active_nur: observable,
    active_subcategory: observable,
    active_subcats: observable,
    active_product: observable,
    active_togglenav: observable,
    active_sbnav: observable,
    active_subnav: observable,
    active_video: observable,
    active_video_cc: observable,
    active_video_playing: observable,
    active_video_location: observable,
    carousel_home: observable,
    carousel_home_class: observable,
    content_component: observable,
    content_text: observable,
    cookie_duration: observable,
    count_total: observable,
    features_list: observable,
    featureAll: observable,
    foldout: observable,
    form_feedback: observable,
    freezePaging: observable,
    freezeScrolling: observable,
    isDialogContentOpen: observable,
    isDialogVideoOpen: observable,
    list_languages: observable,
    newsletters: observable,
    newsletter_active: observable,
    payment_providers: observable,
    userDiscounts: observable,
    quote: observable,
    banner: observable,
    notifications: observable,
    order_to_process: observable,
    page_view: observable,
    payment_status: observable,
    scrollPos: observable,
    search_intentions: observable,
    search_inputRef: observable,
    search_input_focus: observable,
    search_overlay: observable,
    search_overlayRef: observable,
    search_results: observable,
    search_results_simple: observable,

    search_results_simple_own: observable,
    search_results_simple_sb: observable,
    search_results_simple_all: observable,

    searchTopItemTitle: observable,
    searchTopItemTitleSku: observable,
    searchTopItemScore: observable,

    search_results_alternative: observable,
    
    search_results_own: observable,
    search_results_sb: observable,
    search_results_all: observable,

    search_results_newsletter: observable,

    search_count: observable,
    search_count_alternative: observable,
    search_count_own: observable,
    search_count_sb: observable,
    search_count_all: observable,
    search_value: observable,
    search_value_saved: observable,
    searchFeedback: observable,
    settings_list: observable,
    social_links: observable,
    stockPriority: observable,
    templateView: observable,
    toggle_nav: observable,
    wrapperRef: observable,
    videos: observable,
    addReview: action,
    checkCookies: action,
    checkFeature: action,
    checkFile: action,
    checkIfNURCategory: action,
    checkPassword: action,
    checkVATNumber: action,
    checkShopForCategory: action,
    checkShopForSubCategory: action,
    checkShopForSku: action,
    clearFormFeedback: action,
    closeDialog: action,
    closeDialogContent: action,
    closeDialogDelete: action,
    closeDialogFeedbackForm: action,
    closeDialogNewsletter: action,
    closeDialogNotifications: action,
    closeDialogVideo: action,
    closeDialogWishList: action,
    copyCoverForSharing: action,
    getBanner: action,
    getBookCountForAuthor: action,
    getBookCountForCategory: action,
    getBookCountForSubCategory: action,
    getBookCountForLanguage: action,
    getBookCountForBooksNew: action,
    getBookCountForBooksSB: action,
    getCarousel: action,
    getElevatedSku: action,
    getMainCategory: action,
    getProductCountForCategory: action,
    getProductCountForSubGroup: action,
    getContentText: action,
    getNewsletters: action,
    getProductDiscount: action,
    getQuote: action,
    getSearchCount: action,
    getSearchCountSB: action,
    getSearchResults: action,
    getSearchResultsSB: action,
    getSearchResultsSimple: action,
    getSearchIntentions: action,
    getShortUrl: action,
    getTooltip: action,
    getUserDiscounts: action,
    getVideos: action,
    getVideoById: action,
    getVideoByReference: action,
    openDialog: action,
    openDialogContent: action,
    openDialogDelete: action,
    openDialogFeedbackForm: action,
    openDialogNewsletter: action,
    openDialogNotifications: action,
    openDialogVideo: action,
    openDialogWishList: action,
    redirectISBN: action,
    reloadOrderLines: action,
    resetSearchOverlayValues: action,
    retrievePassword: action,
    returnBookCountForAuthorSimple: action,
    saveSearchQuery: action,
    sendFormSendToFriend: action,
    sendFormPress: action,
    sendFormReview: action,
    setAccountView: action,
    setActiveNav: action,
    setActiveGenre: action,
    setActiveCategory: action,
    setActiveCategoryDisplayValue: action,
    setActiveSubCats: action,
    setActiveLanguage: action,
    setActiveMainNav: action,
    setActiveNewsletter: action,
    setActiveProductGroup: action,
    setActiveSubCategory: action,
    setActiveSubGroup: action,
    setActiveSearchSegment: action,
    setActiveToggleNav: action,
    setActiveSubNav: action,
    setActiveTab: action,
    setActiveVideo: action,
    setActiveVideoCC: action,
    setCarousel: action,
    setContentComponent: action,
    setCookies: action,
    setCurrentAuthor: action,
    setDirectLogin: action,
    setFeatureAll: action,
    setFoldout: action,
    setFreezePaging: action,
    setFreezeScrolling: action,
    setFreezePos: action,
    setOrderToProcess: action,
    setOrderToProcessKey: action,
    setPageView: action,
    setReturnState: action,
    setCurrentBook: action,
    setSearchFeedback: action,
    setSearchInputFocus: action,
    setSearchInputRef: action,
    setSearchOverlayRef: action,
    setSearchOverlay: action,
    setSearchResultsSimple: action,
    setShowSubCats: action,
    setShowSubGroups: action,
    setFormFeedback: action,
    setHeightFooter: action,
    setHeightHeader: action,
    setNavMobile: action,
    setSearchValue: action,
    setTemplateView: action,
    setUseCase: action,
    setUseCaseOrder: action,
    setWrapperRef: action,
    showTooltip: action,
    translateNURtoValue: action
})

export default StoreUi;