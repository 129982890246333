import { observable, computed, action, decorate } from "mobx";
import * as mobx from 'mobx';
import { runInAction } from "mobx";
import moment from "moment";

import * as authors from '../communicator/authors';
import * as content from '../communicator/content';
import * as genres from '../communicator/genres';
import * as categories from '../communicator/categories';
import * as catalog from '../communicator/catalog';
import * as books from '../communicator/books';
import * as booklists from '../communicator/book-lists';
import * as products from '../communicator/products';
import * as settings from '../communicator/settings';
import * as sort from '../utilities/sort';
import { createContext } from "react";

class StoreBooks {
    authorsList = []
    authorBooksList = []
    genresList = []
    categoriesList = []
    categoriesListSB = []
    categoriesListALL = []
    connCategoriesGenresList = []
    current_book = {};
    booksList = []
    bookList_all = []
    bookList_category = []
    bookList_language = []
    bookList_sb = []
    bookList_new = []
    bookList_newest = []
    bookList_related = []
    bookList_subcat = []
    bookList_top10 = []
    bookList_top20 = []
    bookList_top60 = []
    preview_audio = [{}]
    preview_video = [{}]
    reviewsList = []
    newsletter_tips = []
    tips_carousel_home = []
    settings_list = []
    //media_list = []
    list_downloads = []

    listCategoriesPopular = []

    constructor(storeRoot) {
        this.storeRoot = storeRoot;
        this.storeGeneral = this.storeRoot.storeGeneral;
        this.getSettings();
        //this.getNewsletterTips();
        //this.getGenres();
        //this.getCategories();
        //this.getMediaGroups();
        //this.getConnCategoriesGenres();
        this.getTipsCarouselHome();
        this.getBooksNewestAuto();
        this.getBooksTop20();
        this.getAuthors();
        this.getCategoriesPopular();
        this.getBooksTop60();
    }

    async getSettings() {
        try {
            const returnData = await settings.getSettings();
            runInAction(() => {
                this.getNewsletterTips();
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async getAuthors() {
        try {
            const returnData = await authors.getAuthors();
            runInAction(() => {
                this.authorsList = returnData.data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    checkAuthorIsSB(firstname, lastname) {
        const idx = this.authorsList
            .findIndex((author) => author.firstname === firstname && author.lastname === lastname);
        return (idx > -1 && this.authorsList[idx].is_sb) ? true : false;
    }

    async getGenres() {
        try {
            const genre = await genres.getGenres();
            runInAction(() => {
                this.genresList = genre.data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    // async getMediaGroups() {
    //     try {
    //         const cats = await categories.getMediaGroups();
    //         runInAction(() => {
    //             this.media_list = cats.data;
    //         })
    //     } catch (error) {
    //         runInAction(() => {
    //             this.state = "error"
    //         })
    //     }
    // }

    async getCategories(id_shop) {
        let shop;

        if (id_shop) {
            shop = id_shop;
        } else {
            shop = this.storeGeneral.shop;
        }

        try {
            const returnData = await categories.getCategories(shop);
            runInAction(() => {
                this.categoriesList = returnData.data;

                if (shop === 'SHOP_SB') {
                    this.categoriesListSB = returnData.data;
                } else {
                    this.categoriesListALL = returnData.data;
                }
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async returnCategories(id_shop) {
        let shop;
        let cats;

        if (id_shop) {
            shop = id_shop;
        } else {
            shop = this.storeGeneral.shop;
        }

        try {
            const returnData = await categories.getCategories(shop);
            runInAction(() => {
                cats = returnData.data;
                //this.categoriesList = returnData.data;
                return cats;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return cats;
    }

    async getCategoriesPopular() {
        let catsPopular;
        try {
            const cats = await categories.getCategoriesPopular();
            runInAction(() => {
                this.listCategoriesPopular = cats.data;
                catsPopular = cats.data;
                return catsPopular;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return catsPopular;
    }

    async getSubCategories(cat) {
        const shop = this.storeGeneral.shop;

        let cats;
        try {
            cats = await categories.getSubCategories(shop, cat);
            runInAction(() => {
                return cats.data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return cats.data;
    }

    // async getProductGroups() {
    //     try {
    //         const cats = await products.getProductGroups();
    //         runInAction(() => {
    //             this.media_list = cats.data;
    //         })
    //     } catch (error) {
    //         runInAction(() => {
    //             this.state = "error"
    //         })
    //     }
    // }

    async getConnCategoriesGenres() {
        try {
            const returnData = await catalog.getConnCategoriesGenres();
            runInAction(() => {
                this.connCategoriesGenresList = returnData.data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async getBooks(shop, page_view) {
        try {
            const books = await booklists.getBooks(shop, page_view);
            runInAction(() => {
                this.bookList_sb = books.data;
            })
        } catch (error) {
            runInAction(() => {
                console.log(error)
            })
        }
    }

    getBooksWithAbort(shop, page_view, controller, signal) {
        let books;
        try {
            books = booklists.getBooksWithAbort(shop, page_view, controller, signal);
            runInAction(() => {
                //this.bookList_sb = books.data;
                return books;
            })
        } catch (error) {
            runInAction(() => {
                console.log(error)
            })
        }
        return books;
    }

    setBookListSb(books) {
        this.bookList_sb = books;
    }

    async getSbPublications(collection) {
        try {
            const books = await catalog.getSbPublications(collection);
            runInAction(() => {
                this.bookList_sb = books.data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async getBooksForAuthor(page_view, str, firstn, lastn, shop) {
        this.storeGeneral.startLoader();
        let shop2;
        let firstname;
        let lastname;

        if (firstn !== undefined && lastn !== undefined && firstn !== '' && lastn !== '') {
            firstname = firstn;
            lastname = lastn;
        } else {
            if (!Number.isInteger(str)) {
                let arr = str.split(/[ ]+/);

                if (arr.length > 2) { 
                    if (arr[1].includes(".")) {
                        firstname = arr[0] + ' ' + arr[1];
                        lastname = str.substr(str.indexOf('.')+1).trim();
                    } else {
                        firstname = str.substr(0, str.indexOf(' '));
                        lastname = str.substr(str.indexOf(' ') + 1);
                    }
                } else {
                    firstname = str.substr(0, str.indexOf(' '));
                    lastname = str.substr(str.indexOf(' ') + 1);
                }
            } 
        }

        if (shop !== undefined) {
            shop2 = shop;
        } else {
            if (this.storeGeneral.shop !== 'SHOP_ALL') {
                shop2 = 'SHOP_SB';
            } else {
                if (this.checkAuthorIsSB(firstname, lastname)) {
                    shop2 = 'SHOP_SB';
                } else {
                    shop2 = 'SHOP_ALL';
                }
            }
        }

        try {
            const books = await authors.getBooksForAuthor(shop2, str, firstname, lastname, page_view);
            runInAction(() => {     
                this.authorBooksList = books.data;
             })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {
            this.storeGeneral.stopLoader();
        }
    }

    async getBooksForCategory(cat, page_view, stock_priority) {
        this.storeGeneral.startLoader();
        const shop = this.storeGeneral.shop;
        const priority = (stock_priority !== undefined) ? stock_priority : 1;
       
        try {
            const books = await booklists.getBooksForCategory(shop, cat, page_view, priority);
            runInAction(() => {
                this.bookList_category = books.data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {
            this.storeGeneral.stopLoader();
        }
    }

    async returnBooksForCategory(cat, page_view, stock_priority) {
        this.storeGeneral.startLoader();
        const shop = this.storeGeneral.shop;
        const priority = (stock_priority !== undefined) ? stock_priority : 1;
        let bookList;
       
        try {
            const books = await booklists.getBooksForCategory(shop, cat, page_view, priority);
            runInAction(() => {
                bookList = books.data;
                
                return bookList;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {
            this.storeGeneral.stopLoader();
        }

        return bookList;
    }

    getBooksForCategoryWithAbort(cat, page_view, stock_priority, controller, signal) {
        this.storeGeneral.startLoader();
        const shop = this.storeGeneral.shop;
        const priority = (stock_priority !== undefined) ? stock_priority : 1;
        let books;
       
        try {
            books = booklists.getBooksForCategoryWithAbort(shop, cat, page_view, priority, controller, signal);
            runInAction(() => {
                return books;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {
            this.storeGeneral.stopLoader();
        }
        return books;
    }

    setBookListCategory(books) {
        this.bookList_category = books;
    }

    async getBooksForSubCategory(cat, subcat, page_view, stock_priority) {
        this.storeGeneral.startLoader();
        const shop = this.storeGeneral.shop;

        const priority = (stock_priority !== undefined) ? stock_priority : 1;
        try {
            const books = await booklists.getBooksForSubCategory(shop, cat, subcat, page_view, priority);
            runInAction(() => {
                this.bookList_subcat = books.data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {
            this.storeGeneral.stopLoader();
        }
    }

    getBooksForSubCategoryWithAbort(cat, subcat, page_view, stock_priority, controller, signal) {
        this.storeGeneral.startLoader();
        const shop = this.storeGeneral.shop;
        let books;
        
        const priority = (stock_priority !== undefined) ? stock_priority : 1;

        try {
            books = booklists.getBooksForSubCategoryWithAbort(shop, cat, subcat, page_view, priority, controller, signal);
            runInAction(() => {
                return books;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {
            this.storeGeneral.stopLoader();
        }
        return books;
    }

    setBookListSubCategory(books) {
        this.bookList_subcat = books;
    }


    async getBooksForSubCategoryAll(subcat, page_view, stock_priority) {
        this.storeGeneral.startLoader();
        const shop = this.storeGeneral.shop;

        const priority = (stock_priority !== undefined) ? stock_priority : 1;
        try {
            const books = await booklists.getBooksForSubCategoryAll(shop, subcat, page_view, priority);
            runInAction(() => {
                this.bookList_subcat = books.data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {
            this.storeGeneral.stopLoader();
        }
    }

    async getBooksForLanguage(lang, page_view, stock_priority) {
        this.storeGeneral.startLoader();
        const shop = this.storeGeneral.shop;
        const priority = (stock_priority !== undefined) ? stock_priority : 1;
        try {
            const books = await booklists.getBooksForLanguage(shop, lang, page_view, priority);
            runInAction(() => {
                this.bookList_language = books.data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {
            this.storeGeneral.stopLoader();
        }
    }

    async getBookDetails(id) {
        //SbC DEPRICATED
        // const shop = this.storeGeneral.shop;

        // try {
        //     const book = await books.getBookDetails(shop, id);
        //     runInAction(() => {
        //         this.current_book = book.data[0];
        //     })
        // } catch (error) {
        //     runInAction(() => {
        //         this.state = "error"
        //     })
        // }
    }

    async returnBookDetails(id) {
        let book_details;
        try {
            const book = await books.getBookDetails(id);
            runInAction(() => {
                book_details = book.data[0];
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return book_details;
    }

    async returnBookDetailsBySku(shop, sku) {
        let book_details;
        try {
            const book = await books.getBookDetailsBySku(shop, sku);
            runInAction(() => {
                book_details = book.data[0];
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return book_details;
    }

    async getBookDetailsBySku(sku) {
        const shop = this.storeGeneral.shop;
        //const shop = 'SHOP_ALL';

        try {
            const book = await books.getBookDetailsBySku(shop, sku);
            runInAction(() => {
                this.current_book = book.data[0];
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async getBookBySku(sku, set_shop) {
        let shop = this.storeGeneral.shop;
        
        if (set_shop) {
            shop = set_shop;
        }

        if (shop === '') {
            shop = 'SHOP_ALL';
        }

        let current_book;
        try {
            const book = await books.getBookDetailsBySku(shop, sku);
            runInAction(() => {
                current_book = book.data[0];
                this.current_book = book.data[0];
                return current_book;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        } finally {
            return current_book;
        }
    }

    async getBooksRelated(sku) {
        try {
            const books = await booklists.getBooksRelated(sku);
            runInAction(() => {
                this.bookList_related = books.data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async getBooksNew(view, page_view) {
        let shop = this.storeGeneral.activeShop;

        try {
            const books = await booklists.getBooksNew(shop, view, page_view);
            runInAction(() => {
                this.bookList_new = books.data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async getBooksNewest() {
        let result_data;
        let result_ordering;
        this.bookList_newest = [];
        let idx;
        try {
            const returnData = await booklists.getBooksNewest();
            runInAction(() => {
                result_data = returnData.data;
                result_ordering = returnData.order;

                result_ordering.map(sku => {
                    idx = result_data
                        .findIndex((item) => item.sku === sku);
                    this.bookList_newest.push(result_data[idx])
                })
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async getBooksNewestAuto() {
        this.bookList_newest = [];
        try {
            const returnData = await booklists.getBooksNewestAuto();
            runInAction(() => {
                this.bookList_newest = returnData.data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async getTop10s() {
        let result;
        try {
            const returnData = await booklists.getTop10s();
            runInAction(() => {
                result = returnData.data;
                return result;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return result;
    }

    async getTop10NUR(tag) {
        let result;
        try {
            const returnData = await booklists.getTop10NUR(tag);
            runInAction(() => {
                result = returnData;
                return result;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return result;
    }

    async getBooksTop10(tag, nur, isAutofill) {
        let result_data;
        let result_ordering;
        let bookList_top10 = [];
        let idx;

        if (tag !== undefined && nur !== undefined) {
            try {
                const returnData = await booklists.getBooksTop10(tag, nur, isAutofill);
                runInAction(() => {
                    result_data = returnData.data.data;
                    result_ordering = returnData.data.order;

                    result_ordering.map(sku => {
                        idx = result_data
                            .findIndex((item) => item.sku === sku);
                        bookList_top10.push(result_data[idx])
                    })

                    if (tag === 'top10fiction') {
                        this.bookList_top10 = bookList_top10;
                    }

                    return bookList_top10;
                })
            } catch (error) {
                runInAction(() => {
                    this.state = "error"
                })
            }
        } 

        return bookList_top10;
    }

    async getBooksTop20() {
        let result_data;
        let result_ordering;
        this.bookList_top20 = [];
        let idx;
        
        try {
            const returnData = await booklists.getBooksTop20();
            runInAction(() => {
                result_data = returnData.data.data;
                result_ordering = returnData.data.order;

                result_ordering.map(sku => {
                    idx = result_data
                        .findIndex((item) => item.sku === sku);
                    this.bookList_top20.push(result_data[idx])
                })
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async getBooksTop60() {
        let result_data;
        let result_ordering;
        this.bookList_top60 = [];
        let idx;

        try {
            const returnData = await booklists.getBooksTop60();
            runInAction(() => {
                result_data = returnData.data.data;
                result_ordering = returnData.data.order;

                if (this.bookList_top60.length === 0) {
                    result_ordering.map(sku => {
                        idx = result_data
                            .findIndex((item) => item.sku == sku);
                        this.bookList_top60.push(result_data[idx])
                    })
                }
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async getWeekTop60() {
        let weeknr;

        try {
            const returnData = await booklists.getWeekTop60();
            runInAction(() => {
                weeknr = returnData.data;
                
                return weeknr;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }

        return weeknr;
    }

    async getNewsletterTips() {  
        let result_data;
        let result_ordering;
        this.newsletter_tips = [];
        let idx;
        const newsletter_id = this.storeGeneral.settings_list['tagLatestNewsletter'];
        try {
            const returnData = await content.getNewsletterTips(newsletter_id);
            runInAction(() => {
                result_data = returnData.data.data;
                result_ordering = returnData.data.order;

                result_ordering.map(sku => {
                    idx = result_data
                        .findIndex((item) => item.sku === sku);
                    this.newsletter_tips.push(result_data[idx])
                })
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async getTipsCarouselHome() {  
        try {
            const returnData = await content.getTipsCarouselHome();
            runInAction(() => {
                this.tips_carousel_home = returnData.data.data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async getPreview(media, isbn) {    
        let result;
        try {
            const preview = await catalog.getPreview(media, isbn);
            runInAction(() => {
                if (media === 'audio') {
                    this.preview_audio = preview.data;
                }
                if (media === 'video') {
                    this.preview_video = preview.data
                }
                result = preview.data;
                return result;

            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return result;
    }

    async getPreviewVideo(media, isbn) {    
        let result;
        try {
            const preview = await catalog.getPreviewVideo(isbn);
            runInAction(() => {
                if (media == 'audio') {
                    this.preview_audio = preview.data;
                }
                if (media == 'video') {
                    this.preview_video = preview.data;
                }
                result = preview.data;
                return result;

            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return result;
    }

    async getReviews(sku) {     
        try {
            const reviews = await catalog.getReviews(sku);
            runInAction(() => {
                this.reviewsList = reviews.data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }

    async getReviewer(id) {  
        let result;   
        try {
            const reviewer = await catalog.getReviewer(id);
            runInAction(() => {
                result = reviewer.data;
                return result;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return result;
    }

    async getBookISBN(id) {
        let isbn;
        try {
            const returnData = await books.getBookISBN(id);
            runInAction(() => {
                isbn = returnData.data[0].sku;
                return isbn;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return isbn;
    }

    async getBookCategories(sku) {
        const shop = this.storeGeneral.shop;
        let cats;
        try {
            const returnData = await books.getBookCategories(shop, sku);
            runInAction(() => {
                cats = returnData.data;
                return cats;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return cats;
    }

    async getBookTags(sku) {
        let tags;
        try {
            const returnData = await books.getBookTags(sku);
            runInAction(() => {
                tags = returnData.data;
                return tags;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
        return tags;
    }

    async getBookDownloads(sku) {
        try {
            const returnData = await books.getBookDownloads(sku);
            runInAction(() => {
                this.list_downloads = returnData.data;
            })
        } catch (error) {
            runInAction(() => {
                this.state = "error"
            })
        }
    }
}

decorate(StoreBooks, {
    authorsList: observable,
    authorBooksList: observable,
    booksList: observable,
    bookList_all: observable,
    bookList_category: observable,
    bookList_sb: observable,
    bookList_language: observable,
    bookList_new: observable,
    bookList_newest: observable,
    bookList_related: observable,
    bookList_subcat: observable,
    bookList_top10: observable,
    bookList_top20: observable,
    bookList_top60: observable,
    categoriesList: observable,
    categoriesListSB: observable,
    categoriesListALL: observable,
    current_book: observable,
    genresList: observable,
    list_downloads: observable,
    listCategoriesPopular: observable,
    newsletter_tips: observable,
    preview_audio: observable,
    preview_video: observable,
    reviewsList: observable,
    settings_list: observable,
    tips_carousel_home: observable,
    checkAuthorIsSB: action,
    getBookCategories: action,
    getBookDownloads: action,
    getBookISBN: action,
    getBooksTop10: action,
    getBooksTop60: action,
    getBooks: action,
    getBooksForAuthor: action,
    getBooksForCategory: action,
    getBooksForCategoryWithAbort: action,
    getBooksForLanguage: action,
    getBooksForSubCategory: action,
    getBooksForSubCategoryWithAbort: action,
    getBooksNew: action,
    getBooksNewest: action,
    getBooksNewestAuto: action,
    getBooksRelated: action,
    getBookTags: action,
    getCategories: action,
    getCategoriesPopular: action,
    getNewsletterTips: action,
    getSbPublications: action,
    getSubCategories: action,
    getBookDetails: action,
    getBookbySku: action,
    getBookDetailsBySku: action,
    getPreview: action,
    getPreviewVideo: action,
    getReviews: action,
    getReviewer: action,
    getTop10s: action,
    getTop10NUR: action,
    returnBookDetails: action,
    returnCategories: action,
    setBookListCategory: action
})

export default StoreBooks;