import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import i18n from "i18next";
import { withTranslation } from 'react-i18next';
import { withRouter } from "react-router-dom";
import {
    isBrowser,
    isMobile
} from "react-device-detect";

import validate from '../../utilities/validation-rules';
import * as content from '../../utilities/content';
import * as utilities from '../../utilities/formats';

import TextInputSearch from '../../elements/form-input-text-search';

const SearchInput = inject("stores") (
    observer (
        class SearchInput extends Component {

            constructor(props) {
                super(props);
                this.props = props;
                     
                this.storeAuth = this.props.stores.storeAuth;
                this.storeGeneral = this.props.stores.storeGeneral;
                this.storeUi = this.props.stores.storeUi;
                this.storeBooks = this.props.stores.storeBooks;  
                this.storeUser = this.props.stores.storeUser;   
                
                this.state = {
                    formControls: { 
                        search_input: {
                            label: i18n.t("forms.label.search_input"),
                            value: this.storeUi.search_value,
                            touched: false,
                            valid: true
                        }
                    },
                    formIsValid: true,
                    btn_class: '--disabled',
                    focus: false
                }

                this.controller = new AbortController();
                this.signal = this.controller.signal;
            }
            
            componentDidMount() {
                document.addEventListener('mousedown', this.handleIfFocus);

                document.addEventListener("keyup", (event) => {
                    if (event.keyCode === 13) {
                        event.preventDefault();
                        this.storeUi.setSearchOverlay(false);
                        this.storeUi.setSearchInputFocus(false);
                        this.onSubmit(event);
                    }
                });
            }

            componentDidUpdate = (prevProps) => {
                if (prevProps.search_value !== this.props.search_value) {
                    this.handleSuggestion(this.props.search_value);
                    this.handleInput(this.props.search_value);
                }
            }

            handleOnEnter = (event) => {
                if (this.inputRef) {
                    this.inputRef.addEventListener("keyup", (event) => {
                        if (event.keyCode === 13) {
                            event.preventDefault();
                            this.onSubmit(event);
                        }
                    })
                }
            }

            handleIfFocus = (event) => {
                if ((this.storeUi.search_overlayRef && !this.storeUi.search_overlayRef.contains(event.target)) && 
                    (this.storeUi.search_inputRef && !this.storeUi.search_inputRef.contains(event.target)) &&
                    (this.storeUi.wrapperRef && this.storeUi.wrapperRef.contains(event.target))) {
                    if (event.target.innerHTML !== 'Alle boeken' && 
                        event.target.innerHTML !== 'Alle zelfhulpboeken'&& 
                        event.target.innerHTML !== 'Boeken eigen uitgeverij') {
                            this.storeUi.setSearchOverlay(false);
                    }
                    this.storeUi.setSearchInputFocus(false);
                }

                if (this.storeUi.search_inputRef && this.storeUi.search_inputRef.contains(event.target)) {
                    this.storeUi.setSearchOverlay(true);
                    this.storeUi.setSearchInputFocus(true);
                }
            }

            handleOnFocus = async (value) => {
                if (this.storeUi.search_value.trim().length >= 3 || content.searchChars(this.storeUi.search_value.trim())) {
                //if (this.storeUi.search_value.length >= 3 || content.searchChars(this.storeUi.search_value)) {
                    this.storeUi.setSearchOverlay(true);
                } else {
                    this.storeUi.setSearchOverlay(false);
                }

                this.setState({
                    focus: value.current
                })
            }

            removeOnBlur(event) {
                if (this.storeUi.search_overlayRef && !this.storeUi.search_overlayRef.contains(event.target)) {
                    this.storeUi.setSearchOverlay(false);
                    this.storeUi.setSearchInputFocus(false);
                }
                
            }

            setInputRef = (node) => {
                this.inputRef = node;
                this.storeUi.setSearchInputRef(node);
            }

            handleChange = event => {     
                const name = event.target.name;
                const value = event.target.value;
                
                if (value !== null && value !== ' ') {
                    const updatedControls = {
                    ...this.state.formControls
                    };
                    const updatedFormElement = {
                    ...updatedControls[name]
                    };
                    updatedFormElement.value = value;
                    updatedFormElement.touched = true;
                    //updatedFormElement.valid = validate(value, updatedFormElement.validationRules);
            
                    updatedControls[name] = updatedFormElement;

                    //SbC check if form is valid
                    let formIsValid = true;
                    for (let inputIdentifier in updatedControls) {
                        if (updatedControls[inputIdentifier].required) {
                            formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
                        }
                    }

                    this.setState({
                        formControls: updatedControls,
                        formIsValid: formIsValid,
                        btn_class: formIsValid ? '--active' : '--disabled'
                    }, () => this.handleTimeOut(value)); 
                } 
            }

            handleTimeOut = (value) => {
                clearTimeout(this.timer);
                this.timer = setTimeout(() => {
                    this.handleInput(value);
                }, 500, value);
            } 

            handleInput = async(value2) => {
                let q;
                let section;
                let value;

                value = value2.replace('?', "");
                value = value.replace('!', "");
                //this.storeUi.setSearchValue(value.trim());
                value = content.convertHTML(value);

                this.storeUi.setSearchValue(value);
                
                //if (value !== null && value !== '' && value !== ' ' && (value.trim().length >= 3 || content.searchChars(value.trim()))) {
                if (value !== null && value !== '' && value !== ' ' && (value.length >= 3 || content.searchChars(value))) {
                    this.storeUi.setSearchOverlay(true);

                    //SbC section OWN
                    section = 'sb';
                    await (async() => this.storeUi.getSearchCountSB(value, section) )();
                    // await (async() => this.storeUi.getSearchResultsSimple('SHOP_SB', value, section, this.storeUi.page_view, false) )();
                    q = this.storeUi.getSearchResultsSimpleWithAbort('SHOP_SB', value, section, this.storeUi.page_view, false, this.controller, this.signal);
                    
                    q.then((result) => {  
                        this.storeUi.setSearchResultsSimple('SHOP_SB', 'sb', result.data, value);
                    });

                    //SbC section SB
                    section = '';
                    await (async() => this.storeUi.getSearchCountSB(value, section) )();
                    // await (async() => this.storeUi.getSearchResultsSimple('SHOP_SB', value, '', this.storeUi.page_view, false) )();
                    q = this.storeUi.getSearchResultsSimpleWithAbort('SHOP_SB', value, section, this.storeUi.page_view, false, this.controller, this.signal);

                    q.then((result) => {  
                        this.storeUi.setSearchResultsSimple('SHOP_SB', '', result.data, value);
                    });

                    //SbC section ALL
                    section = 'all';
                    await (async() => this.storeUi.getSearchCount(value, section) )();
                    // await (async() => this.storeUi.getSearchResultsSimple('SHOP_ALL', value, '', this.storeUi.page_view, false) )();
                    q = this.storeUi.getSearchResultsSimpleWithAbort('SHOP_ALL', value, section, this.storeUi.page_view, false, this.controller, this.signal);
                    
                    q.then((result) => {  
                        this.storeUi.setSearchResultsSimple('SHOP_ALL', '', result.data, value);
                    });

                    //SbC get search intentions
                    this.storeUi.getSearchIntentions(value);
                }

                //if (value.trim().length < 3 && !content.searchChars(value.trim())) {
                if (value.length < 3 && !content.searchChars(value)) {
                    this.storeUi.setSearchOverlay(false);
                }
            }

            handleSuggestion = (suggestion) => {
                const name = 'search_input';
                const value = suggestion;
            
                const updatedControls = {
                    ...this.state.formControls
                };
                const updatedFormElement = {
                    ...updatedControls[name]
                };
                updatedFormElement.value = value;
                updatedFormElement.touched = true;
                //updatedFormElement.valid = validate(value, updatedFormElement.validationRules);
            
                updatedControls[name] = updatedFormElement;

                //SbC check if form is valid
                let formIsValid = true;
                // for (let inputIdentifier in updatedControls) {
                //     if (updatedControls[inputIdentifier].required) {
                //         formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
                //     }
                // }

                this.setState({
                    formControls: updatedControls,
                    formIsValid: formIsValid
                }); 
            }

            handleClear = () => {
                const name = 'search_input';
                const value = '';
          
                const updatedControls = {
                  ...this.state.formControls
                };
                const updatedFormElement = {
                  ...updatedControls[name]
                };
                updatedFormElement.value = value;
                updatedFormElement.touched = true;
          
                updatedControls[name] = updatedFormElement;

                this.setState({
                    formControls: updatedControls,
                    formIsValid: false
                }); 
            }

            onSubmit = () => {
                let search_term;
                search_term = this.state.formControls['search_input'].value;
                //search_term = search_term.toString().trim();

                if (this.state.formIsValid && this.state.formControls['search_input'].value) {
                    // if (utilities.isInteger(search_term)) {
                    //     search_term = parseInt(search_term);
                    // }

                    //SbC:: get search results and intentions
                    this.storeUi.setSearchValue(search_term);
                    this.storeUi.setPageView('page', 1);
                    this.storeUi.getSearchIntentions(search_term);
                    this.storeUi.saveSearchQuery(search_term);

                    //SbC:: set main nav to dropdown value after submit
                    this.storeUi.setActiveMainNav(this.storeUi.app_interface.active_search_segment);

                    const path = "/search-results";
                    const str_search = "?q=" + search_term;

                    this.props.history.push({
                        pathname: path,
                        search: str_search
                    })
                }

                this.storeUi.setSearchOverlay(false);
            }

            render() {
                return (
                    <React.Fragment>
                        <div className={"search__input" + (this.props.position === "onpage" ? ' --onpage' : '')}>
                            {this.storeUi.app_interface.use_case === "inshop" &&
                                <React.Fragment>
                                    <div ref={this.setInputRef}>
                                        <TextInputSearch 
                                            name = {'search_input'} 
                                            placeholder =  {this.storeAuth.user.is_loggedin 
                                                ?    i18n.t("forms.placeholder.search_input") + ', ' + utilities.capitalizeFirstChar(this.props.firstname) + '?'
                                                :    i18n.t("forms.placeholder.search_input") + '?'
                                            }
                                            value = {this.state.formControls['search_input'].value}
                                            onChange = {this.handleChange}
                                            onBlur = {() => this.removeOnBlur}
                                            onFocus = {this.handleOnFocus}
                                            valid = {1}
                                        />
                                    </div>

                                    {isMobile &&
                                        <div className="search__button--clear btn--icon icon--clear --link" onClick={(e) => this.handleClear(e)}>
                                            x
                                        </div>
                                    }

                                    <div className="search__buttons">
                                        <span className="search__button--search --link " onClick={() => this.onSubmit()}>
                                            {i18n.t("button.search")}
                                        </span>
                                    </div>
                                </React.Fragment>
                            }
                        </div>     
                    </React.Fragment>
                )
            }
        }
    )
)

export default withTranslation()(withRouter(SearchInput));

